import { __awaiter } from "tslib";
export class HttpResponse {
  constructor(status, headers, data) {
    this.Status = status;
    this.headers = headers;
    this.Data = data;
  }
}
export class Http {
  static get(serviceOptions, path) {
    return __awaiter(this, void 0, void 0, function* () {
      let headers = {
        'X-AppEnvId': serviceOptions.appEnvId
      };
      if (serviceOptions.apiKey) {
        headers['Authorization'] = `ApiKey ${serviceOptions.apiKey}`;
      }
      const options = {
        headers: headers
      };
      const response = yield fetch(this.buildUrl(serviceOptions, path), options);
      try {
        const body = yield response.json();
        return new HttpResponse(response.status, response.headers, body);
      } catch (_a) {
        return new HttpResponse(response.status, response.headers);
      }
    });
  }
  static getWithUserToken(serviceOptions, path, userToken) {
    return __awaiter(this, void 0, void 0, function* () {
      const options = {
        headers: {
          Authorization: `ApiKey ${serviceOptions.apiKey}`,
          'X-AppEnvId': serviceOptions.appEnvId,
          'X-User-Token': userToken
        }
      };
      const response = yield fetch(this.buildUrl(serviceOptions, path), options);
      return new HttpResponse(response.status, response.headers, yield response.json());
    });
  }
  static post(serviceOptions, path, body) {
    return __awaiter(this, void 0, void 0, function* () {
      const options = {
        method: 'POST',
        headers: {
          Authorization: `ApiKey ${serviceOptions.apiKey}`,
          'X-AppEnvId': serviceOptions.appEnvId,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      };
      const response = yield fetch(this.buildUrl(serviceOptions, path), options);
      try {
        const returnbody = yield response.json();
        return new HttpResponse(response.status, response.headers, returnbody);
      } catch (_a) {
        return new HttpResponse(response.status, response.headers);
      }
    });
  }
  static postWithUserToken(serviceOptions, path, userToken, body) {
    return __awaiter(this, void 0, void 0, function* () {
      const options = {
        method: 'POST',
        headers: {
          Authorization: `ApiKey ${serviceOptions.apiKey}`,
          'X-AppEnvId': serviceOptions.appEnvId,
          'X-User-Token': userToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      };
      const response = yield fetch(this.buildUrl(serviceOptions, path), options);
      try {
        const body = yield response.json();
        return new HttpResponse(response.status, response.headers, body);
      } catch (_a) {
        return new HttpResponse(response.status, response.headers);
      }
    });
  }
  static deleteWithUserToken(serviceOptions, path, userToken) {
    return __awaiter(this, void 0, void 0, function* () {
      const options = {
        method: 'DELETE',
        headers: {
          Authorization: `ApiKey ${serviceOptions.apiKey}`,
          'X-AppEnvId': serviceOptions.appEnvId,
          'X-User-Token': userToken
        }
      };
      const response = yield fetch(this.buildUrl(serviceOptions, path), options);
      return new HttpResponse(response.status, response.headers);
    });
  }
  static buildUrl(options, path) {
    return `${options.baseUrl}${path}`;
  }
}