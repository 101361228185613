import { __awaiter } from "tslib";
// @ts-nocheck
import { Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConnectionCutError, ConnectionCutType, ConnectionKeyInUseError, QuotaCheckError, SessionNotAvailableError, SessionNotFoundError, SessionProvisioningError } from '../types/errors';
import { Http } from '../utils/http';
import { Sdp } from '../utils/sdp';
const DefaultLimits = {
  minBitrate: 0,
  maxBitrate: 15000,
  startBitrate: 5000
};
export class StreamingService {
  constructor(serviceOptions) {
    this.serviceOptions = serviceOptions;
    this.connection = null;
    this.streamSource = new Subject();
    this.signaling = new SignalingService(serviceOptions);
    this.stopping = false;
  }
  start(sessionOptions, streamOptions) {
    const ret$ = this.prepareStream(streamOptions).pipe(map(info => info));
    this.startInternal(sessionOptions);
    return ret$;
  }
  startProvisioningSession(provisioningSession) {
    const ret$ = this.prepareStream().pipe(map(info => info));
    const executePoll = () => __awaiter(this, void 0, void 0, function* () {
      try {
        const session = yield this.getSessionData(provisioningSession.id);
        if (!this.isProvisioningSessionData(session)) {
          this.connectInternal(session);
          return 0;
        }
        return session.checkBackIn;
      } catch (error) {
        this.bail(error);
        return 0;
      }
    });
    const poll = () => __awaiter(this, void 0, void 0, function* () {
      let sleepDelay = provisioningSession.checkBackIn;
      do {
        yield new Promise(f => setTimeout(f, sleepDelay * 1000));
        sleepDelay = yield executePoll();
      } while (sleepDelay);
    });
    poll();
    return ret$;
  }
  connect(session) {
    const ret$ = this.prepareStream().pipe(map(info => info));
    this.connectInternal(session);
    return ret$;
  }
  startWithConnectionKey(connectionKey, streamOptions) {
    const ret$ = this.prepareStream(streamOptions).pipe(map(info => info));
    this.startWithConnectionKeyInternal(connectionKey);
    return ret$;
  }
  startWithConnectionCode(connectionCode, streamOptions) {
    const ret$ = this.prepareStream(streamOptions).pipe(map(info => info));
    this.startWithConnectionCodeInternal(connectionCode);
    return ret$;
  }
  checkQuota(sessionOptions) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.post(this.serviceOptions, '/api/v1/session/quota', sessionOptions);
      if (response.Status === 404) {
        throw new QuotaCheckError('app env not found');
      } else if (response.Status < 200 || response.Status > 299) {
        throw new QuotaCheckError('unexpected error');
      }
      return (_a = response.Data) === null || _a === void 0 ? void 0 : _a.isInQuota;
    });
  }
  stop(err) {
    return __awaiter(this, void 0, void 0, function* () {
      if (this.stopping) return;
      try {
        yield this.signaling.signOut();
      } catch (_a) {}
      this.stopping = true;
      this.stopMonitoring();
      if (this.connection) {
        this.connection.close();
        delete this.connection;
        this.connection = null;
      }
      if (this.streamSource) {
        if (err) {
          this.streamSource.error(err);
        } else {
          this.streamSource.next(null);
          this.streamSource.complete();
        }
        this.streamSource = new Subject();
      }
      this.stopping = false;
      this.stream = null;
    });
  }
  getSessionData(sessionId) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.get(this.serviceOptions, `/api/v1/session/${sessionId}`);
      if (response.Status === 404) {
        throw new SessionNotFoundError();
      } else if (response.Status < 200 || response.Status > 299) {
        throw new SessionNotAvailableError(SessionNotAvailableError.E_NO_AVAILABLE);
      } else {
        if (response.Data.type == 'session') {
          return response.Data;
        } else {
          return response.Data;
        }
      }
    });
  }
  getTwilioToken(sessionId) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.get(this.serviceOptions, `/api/v1/session/${sessionId}/twiliotoken`);
      if (response.Status === 404) {
        throw new SessionNotFoundError();
      } else if (response.Status < 200 || response.Status > 299) {
        throw new Error();
      } else {
        return response.Data;
      }
    });
  }
  getSessionDataByConnectionKey(connectionKey) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.get(this.serviceOptions, `/api/v1/connection/key/${connectionKey}`);
      if (response.Status === 404) {
        throw new SessionNotFoundError();
      } else if (response.Status < 200 || response.Status > 299) {
        throw new SessionNotAvailableError(SessionNotAvailableError.E_NO_AVAILABLE);
      } else {
        if (response.Data.type == 'session') {
          return response.Data;
        } else {
          return response.Data;
        }
      }
    });
  }
  startInternal(sessionOptions) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const session = yield this.createSession(sessionOptions);
        if (this.isProvisioningSessionData(session)) {
          this.bail(new SessionProvisioningError(session));
        } else {
          const sessionData = session;
          yield this.connectInternal(sessionData);
        }
      } catch (error) {
        this.bail(error);
      }
    });
  }
  connectInternal(sessionData) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        yield this.signaling.start('client', sessionData.renderer.rendererId, sessionData.mainConnection.connectionKey);
        yield this.call(sessionData);
      } catch (error) {
        this.bail(error);
      }
    });
  }
  startWithConnectionKeyInternal(connectionKey) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        // TODO: handle provisioning
        const session = yield this.getSessionForConnectionKey(connectionKey);
        yield this.signaling.start('client', session.renderer.rendererId, connectionKey);
        yield this.call(session);
      } catch (error) {
        this.bail(error);
      }
    });
  }
  startWithConnectionCodeInternal(connectionCode) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        // TODO: handle provisioning
        const keyData = yield this.getConnectionKeyForConnectionCode(connectionCode);
        yield this.startWithConnectionKeyInternal(keyData.connectionKey);
      } catch (error) {
        this.bail(error);
      }
    });
  }
  createSession(sessionOptions) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const response = yield Http.post(this.serviceOptions, '/api/v1/session', sessionOptions);
        if (response.Status === 503) {
          const errorCode = response.headers.get('X-ErrorCode');
          this.bail(new SessionNotAvailableError(errorCode));
        } else if (response.Status < 200 || response.Status > 299) {
          this.bail(new Error(`unexpected status code: ${response.Status}`));
        } else {
          if (response.Data.type == 'session') {
            return response.Data;
          } else {
            return response.Data;
          }
        }
      } catch (error) {
        this.bail(error);
      }
      return null;
    });
  }
  getSessionForConnectionKey(connectionKey) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.get(this.serviceOptions, `/api/v1/connection/key/${connectionKey}`);
      if (response.Status === 404) {
        throw new SessionNotFoundError();
      } else if (response.Status !== 200) {
        throw new Error();
      }
      return response.Data;
    });
  }
  getConnectionKeyForConnectionCode(connectionCode) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.get(this.serviceOptions, `/api/v1/connection/code/${connectionCode}`);
      if (response.Status === 404) {
        throw new SessionNotFoundError();
      } else if (response.Status !== 200) {
        throw new Error();
      }
      return response.Data;
    });
  }
  bail(err) {
    var _a;
    this.stream = null;
    this.stopMonitoring();
    this.messageSubscription && this.messageSubscription.unsubscribe();
    this.messageSubscription = null;
    this.signaling.signOut();
    (_a = this.streamSource) === null || _a === void 0 ? void 0 : _a.error(err);
    this.streamSource = new Subject();
  }
  prepareStream(options) {
    var _a;
    this.streamOptions = options || {};
    this.streamOptions.streamLimits = (_a = this.streamOptions.streamLimits) !== null && _a !== void 0 ? _a : DefaultLimits;
    this.connection = null;
    const ret$ = this.streamSource.asObservable();
    this.messageSubscription && this.messageSubscription.unsubscribe();
    this.messageSubscription = this.signaling.message$.subscribe(message => __awaiter(this, void 0, void 0, function* () {
      if (!this.connection) return;
      if (!message) {
        console.log('no message');
        yield this.stop();
        return;
      }
      const answer = JSON.parse(message);
      if (answer.type === 'answer') {
        this.connection.setRemoteDescription(answer).catch(error => {
          console.log('set remote description failed');
          console.log(error);
        });
      } else if (answer.type === 'offer') {
        try {
          yield this.connection.setRemoteDescription(answer);
          const connectionAnswer = yield this.connection.createAnswer();
          const reply = {
            type: 'answer',
            sdp: connectionAnswer.sdp
          };
          console.log('sending answer ' + reply);
          try {
            yield this.signaling.sendMessage(JSON.stringify(reply));
          } catch (error) {
            this.bail(error);
          }
        } catch (error) {
          console.log('set remote description failed');
          console.log(error);
        }
      } else if (answer.candidate !== undefined) {
        try {
          yield this.connection.addIceCandidate(answer);
          console.log('addIceCandidate succeeded');
        } catch (error) {
          console.log('add ice candidate failed');
        }
      }
    }));
    return ret$;
  }
  call(session) {
    return __awaiter(this, void 0, void 0, function* () {
      let connected = false;
      const specs = session.renderer.specs;
      const turnServer = (specs === null || specs === void 0 ? void 0 : specs['turnServer']) || (specs === null || specs === void 0 ? void 0 : specs['publicDomainName']);
      let iceServers = [];
      if (turnServer) {
        iceServers.splice(iceServers.length, 0, {
          urls: [`turns:${turnServer}:443?transport=tcp`],
          username: 'test',
          credential: 'test'
        });
      }
      const configuration = {
        iceServers: iceServers
      };
      this.connection = new RTCPeerConnection(configuration);
      this.connection.oniceconnectionstatechange = e => __awaiter(this, void 0, void 0, function* () {
        if (connected && !this.stopping && (this.connection.iceConnectionState === 'disconnected' || this.connection.iceConnectionState === 'closed' || this.connection.iceConnectionState === 'failed')) {
          console.log('ice connection lost');
          yield this.stop(new Error('RTC connection lost'));
        }
      });
      this.connection.ontrack = e => __awaiter(this, void 0, void 0, function* () {
        const track = !e.streams[0] ? e.transceiver.receiver.track : e.streams[0].getTracks()[0];
        const firstTrack = !this.stream;
        if (firstTrack) {
          connected = true;
          this.stream = new MediaStream();
        }
        track.enabled = true;
        this.stream.addTrack(track);
        if (firstTrack) {
          this.streamSource.next({
            ip: session.renderer.ip,
            stream: this.stream,
            specs: specs,
            session: session
          });
          yield this.startMonitoring();
        }
      });
      try {
        this.connection.addTransceiver('video', {
          direction: 'recvonly'
        });
        if (this.streamOptions.useAudio) {
          this.connection.addTransceiver('audio', {
            direction: 'recvonly'
          });
        }
        let desc = yield this.connection.createOffer();
        let newSdp;
        try {
          newSdp = Sdp.forceH264(desc.sdp, this.streamOptions.streamLimits.startBitrate, this.streamOptions.streamLimits.minBitrate, this.streamOptions.streamLimits.maxBitrate);
          if (this.streamOptions.useAudio) {
            newSdp = Sdp.forceOpus(newSdp);
          }
        } catch (error) {
          this.bail(error);
          return;
        }
        desc.sdp = newSdp;
        const body = {
          type: 'offer',
          sdp: desc.sdp
        };
        yield this.signaling.sendMessage(JSON.stringify(body));
        this.connection.setLocalDescription(desc);
      } catch (error) {
        this.bail(error);
      }
    });
  }
  startMonitoring() {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
      this.stopMonitoring();
      this.monitor = new StreamMonitor(this.connection, this.stream, this.streamOptions, (_c = (_b = (_a = this.streamOptions) === null || _a === void 0 ? void 0 : _a.cutStreamLimits) === null || _b === void 0 ? void 0 : _b.handler) !== null && _c !== void 0 ? _c : this.onMonitoringEvent.bind(this), this.cutStream.bind(this));
      yield this.monitor.start();
    });
  }
  stopMonitoring() {
    if (this.monitor) {
      this.monitor.stop();
      this.monitor = null;
    }
  }
  onMonitoringEvent(_) {
    return true;
  }
  cutStream(data) {
    return __awaiter(this, void 0, void 0, function* () {
      console.log(data);
      yield this.stop(new ConnectionCutError(data.type, data.message));
    });
  }
  isProvisioningSessionData(data) {
    return data.checkBackIn !== undefined;
  }
}
class StreamMonitor {
  constructor(connection, stream, streamOptions, eventHandler, streamCut) {
    this.connection = connection;
    this.stream = stream;
    this.streamOptions = streamOptions;
    this.eventHandler = eventHandler;
    this.streamCut = streamCut;
  }
  start() {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.startStatsTimer();
    });
  }
  stop() {
    this.stopStatsTimer();
  }
  checkStats() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.connection) {
        this.stopStatsTimer();
        return;
      }
      try {
        const stats = yield this.connection.getStats(this.stream.getVideoTracks()[0]);
        stats.forEach(report => __awaiter(this, void 0, void 0, function* () {
          const now = report.timestamp;
          if (this.streamOptions.cutStreamLimits.bitrate && report.type === 'inbound-rtp' && report.mediaType === 'video') {
            let bitrate;
            const bytes = report.bytesReceived;
            if (this.timestampPrev) {
              bitrate = 8 * (bytes - this.bytesPrev) / (now - this.timestampPrev);
              bitrate = Math.floor(bitrate);
            }
            this.bytesPrev = bytes;
            this.timestampPrev = now;
            if (bitrate && bitrate < this.streamOptions.cutStreamLimits.bitrate) {
              const data = {
                type: ConnectionCutType.BITRATE,
                message: `bitrate is too low (${bitrate})`,
                bitrateLimit: this.streamOptions.cutStreamLimits.bitrate,
                currentBitrate: bitrate
              };
              if (this.eventHandler(data)) this.streamCut(data);
            }
          }
          if (this.streamOptions.cutStreamLimits.latency && report.type === 'candidate-pair') {
            let latency = report.currentRoundTripTime * 1000;
            if (latency && latency > this.streamOptions.cutStreamLimits.latency) {
              const data = {
                type: ConnectionCutType.LATENCY,
                message: `latency is too high (${latency})`,
                latencyLimit: this.streamOptions.cutStreamLimits.latency,
                currentLatency: latency
              };
              if (this.eventHandler(data)) this.streamCut(data);
            }
          }
          if (this.streamOptions.cutStreamLimits.noFrames && report.type === 'inbound-rtp' && report.mediaType === 'video') {
            let framesReceived;
            framesReceived = report.framesReceived;
            if (framesReceived && framesReceived > 1) {
              if (framesReceived === this.framesReceivedPrev) {
                const data = {
                  type: ConnectionCutType.FRAMES,
                  message: 'no frames received since last check'
                };
                if (this.eventHandler(data)) this.streamCut(data);
              }
            }
            this.framesReceivedPrev = framesReceived;
          }
        }));
      } catch (_a) {
        console.log('error getting stats, stopping stats watcher');
        this.stopStatsTimer();
      }
    });
  }
  startStatsTimer() {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.streamOptions || !this.streamOptions.cutStreamLimits) return;
      this.stopStatsTimer();
      try {
        const stats = yield this.connection.getStats();
        let hasStats = false;
        stats.forEach(_ => {
          hasStats = true;
        });
        if (!hasStats) {
          throw new Error();
        }
        this.statsTimer = timer(500, 500).subscribe(_ => __awaiter(this, void 0, void 0, function* () {
          yield this.checkStats();
        }));
      } catch (err) {
        console.log('getting stats not supported, configured limits will not be enforced');
      }
    });
  }
  stopStatsTimer() {
    if (this.statsTimer) {
      this.statsTimer.unsubscribe();
      this.statsTimer = null;
      this.bytesPrev = null;
      this.timestampPrev = null;
      this.framesReceivedPrev = null;
    }
  }
}
class SignalingService {
  constructor(serviceOptions) {
    this.serviceOptions = serviceOptions;
    this.messageSource = new Subject();
    this.message$ = this.messageSource.asObservable();
    this.remote_peers = [];
  }
  start(name, remotePeerName, connectionKey) {
    return __awaiter(this, void 0, void 0, function* () {
      yield this.signIn(name, remotePeerName, connectionKey);
    });
  }
  sendMessage(body) {
    return __awaiter(this, void 0, void 0, function* () {
      if (!this.id) throw Error('not signed into signaling');
      yield fetch(this.buildUrl(`/message?peer_id=${this.id}&to=${this.remote_id}`), {
        method: 'POST',
        body: body,
        headers: {
          'X-AppEnvId': this.serviceOptions.appEnvId
        }
      });
    });
  }
  signOut() {
    return __awaiter(this, void 0, void 0, function* () {
      this.stopPollingMessages();
      if (this.id) {
        const id = this.id;
        this.id = null;
        yield fetch(this.buildUrl(`/sign_out?peer_id=${id}`), {
          headers: {
            'X-AppEnvId': this.serviceOptions.appEnvId
          }
        });
      }
    });
  }
  signIn(name, remote_peer_name, connectionKey) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield fetch(this.buildUrl(`/sign_in?peer_name=${name}`), {
        headers: {
          ConnectionKey: connectionKey,
          'X-AppEnvId': this.serviceOptions.appEnvId
        }
      });
      if (response.status === 400) {
        const errorCode = response.headers.get('X-ErrorCode');
        if (errorCode && errorCode === 'E_KEY_IN_USE') throw new ConnectionKeyInUseError();
        throw new SessionNotAvailableError(SessionNotAvailableError.E_NO_SIGNALING);
      }
      if (response.status === 500) throw new SessionNotAvailableError(SessionNotAvailableError.E_NO_SIGNALING);
      this.id = response.headers.get('Pragma');
      const body = yield response.text();
      this.remote_peers = this.getSignalingClients(body);
      if (!this.connect(remote_peer_name)) throw Error('renderer not found on signaling server');
      this.startPollingMessages();
    });
  }
  startPollingMessages() {
    if (!this.id) return;
    this.pollMessage();
  }
  stopPollingMessages() {
    if (this.messageAbort) {
      this.messageAbort.abort();
    }
    if (this.messageSource) {
      this.messageSource.complete();
      this.messageSource = new Subject();
      this.message$ = this.messageSource.asObservable();
    }
  }
  pollMessage() {
    this.messageAbort = new AbortController();
    const {
      signal
    } = this.messageAbort;
    fetch(this.buildUrl(`/wait?peer_id=${this.id}`), {
      signal,
      headers: {
        'X-AppEnvId': this.serviceOptions.appEnvId
      }
    }).then(response => __awaiter(this, void 0, void 0, function* () {
      if (response.status === 504) {
        // longpolling timeout
        this.pollMessage();
        return;
      } else if (response.status < 200 || response.status > 299) {
        this.stopPollingMessages();
        return;
      }
      if (response.headers.get('Pragma') !== this.id) {
        this.messageSource.next(yield response.text());
      }
      this.pollMessage();
    })).catch(e => {
      // TODO: start new request?!
      console.log(e);
    });
  }
  connect(remote_peer_name) {
    const remote = this.remote_peers.find(peer => {
      if (peer.name.localeCompare(remote_peer_name, 'en', {
        sensitivity: 'base'
      }) === 0) return true;
      return false;
    });
    if (!remote) return false;
    this.remote_id = remote.id;
    return true;
  }
  getSignalingClients(data) {
    var lines = data.split('\n').filter(Boolean);
    var peers = [];
    lines.forEach(line => {
      let values = line.split(',');
      let nameSplit = values[0].split(' ');
      let name = nameSplit[0];
      let remote_id = values[1];
      if (remote_id === this.id) return;
      var capacity = 'infinite';
      if (nameSplit.length > 1) {
        capacity = nameSplit[1].replace('(', '').replace(')', '');
      }
      var online = values[2] === '1';
      peers.push({
        id: remote_id,
        name: name,
        capacity: capacity,
        online: online
      });
    });
    return peers;
  }
  buildUrl(path) {
    return `${this.serviceOptions.baseUrl}/signaling${path}`;
  }
}