import { Observable, of } from 'rxjs';
import { first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    REQUEST_SET_ENGINE_OPTIONS,
    SetEngineOptionsPayload
} from '@mhp-immersive-exp/contracts/src/engine-options/set-engine-options-request.interface';

import { ApplicationStateService } from '../application-state';
import { SocketIOService } from '../communication';
import { ErrorHandlerService, RequestReceiverType } from '../error-handler';
import { EngineSettingsService } from './engine-settings.service';

/**
 * Service that copes with changing state of settings related to the
 * engine.
 */
@Injectable({
    providedIn: 'root'
})
export class EngineSettingsWriterService {
    constructor(
        private engineSettingsService: EngineSettingsService,
        private applicationStateService: ApplicationStateService,
        private socketIoService: SocketIOService,
        private errorHandlerService: ErrorHandlerService
    ) {}

    /**
     * Toggle the vr-mode state.
     */
    toggleVrModeState(useDefaultErrorHandling = true): Observable<boolean> {
        return this.engineSettingsService.getVrModeActiveState$().pipe(
            withLatestFrom(
                this.engineSettingsService.getVrModeAvailableState$()
            ),
            first(),
            switchMap(([vrModeActive, vrModeAvailable]) => {
                if (!vrModeAvailable) {
                    return of(false);
                }
                return this.setEngineOptions(
                    {
                        vrEnabled: !vrModeActive
                    },
                    useDefaultErrorHandling
                ).pipe(map(() => !vrModeActive));
            })
        );
    }

    /**
     * Toggles the sound-active state
     */
    toggleSoundActiveState(
        useDefaultErrorHandling = true
    ): Observable<boolean> {
        return this.engineSettingsService.getSoundActiveState$().pipe(
            first(),
            switchMap((soundActive) =>
                this.setEngineOptions(
                    {
                        soundEnabled: !soundActive
                    },
                    useDefaultErrorHandling
                ).pipe(map(() => !soundActive))
            )
        );
    }

    /**
     * Toggles the pricing-active state
     */
    togglePricingActiveState(
        useDefaultErrorHandling = true
    ): Observable<boolean> {
        return this.engineSettingsService.getPricingActiveState$().pipe(
            first(),
            switchMap((pricingActive) =>
                this.setEngineOptions(
                    {
                        pricingEnabled: !pricingActive
                    },
                    useDefaultErrorHandling
                ).pipe(map(() => !pricingActive))
            )
        );
    }

    /**
     * Sets the active language.
     */
    setActiveLanguage(
        language: 'en' | 'it',
        useDefaultErrorHandling = true
    ): Observable<string> {
        return this.setEngineOptions(
            {
                activeLanguage: language
            },
            useDefaultErrorHandling
        ).pipe(map(() => language));
    }

    private setEngineOptions(
        engineOptions: SetEngineOptionsPayload,
        useDefaultErrorHandling: boolean
    ) {
        let request$ = this.socketIoService.request<
            SetEngineOptionsPayload,
            void
        >(REQUEST_SET_ENGINE_OPTIONS, engineOptions);
        if (useDefaultErrorHandling) {
            request$ = request$.pipe(
                this.errorHandlerService.applyRetryWithHintsOnError(
                    (error) =>
                        // TODO: TRANSLATE
                        `Failed applying option: ${(error as Error).message}`,
                    {
                        endpointType: RequestReceiverType.ENGINE
                    }
                )
            );
        }
        return request$;
    }
}
