import { isEmpty } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    ConfigurationCodeDetailsRequestInterface,
    REQUEST_GET_CONFIGURATION_CODE_DETAILS
} from '@mhp-immersive-exp/contracts/src/configuration-code/configuration-code-details-request.interface';
import { ConfigurationCodeDetailsResponseInterface } from '@mhp-immersive-exp/contracts/src/configuration-code/configuration-code-details-response.interface';
import {
    ConfigurationCodeRequestInterface,
    REQUEST_GET_CONFIGURATION_CODE
} from '@mhp-immersive-exp/contracts/src/configuration-code/configuration-code-request.interface';
import { REQUEST_GET_CONFIGURATION_CODES } from '@mhp-immersive-exp/contracts/src/configuration-code/configuration-codes-request.interface';
import { ConfigurationCodesResponseInterface } from '@mhp-immersive-exp/contracts/src/configuration-code/configuration-codes-response.interface';
import { IllegalStateError } from '@mhp/common';

import { StaticAssetService } from '../assets';
import { SocketIOService } from '../communication';
import {
    PersistedProductConfiguration,
    ProductConfiguration
} from './product-configuration.interface';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationManagementService {
    constructor(
        private socketIoService: SocketIOService,
        private staticAssetService: StaticAssetService
    ) {}

    /**
     * Saves the given productConfiguration string and returns a productConfiguration code
     * @param productConfiguration
     */
    saveConfiguration(
        productConfiguration: ProductConfiguration
    ): Observable<string> {
        if (isEmpty(productConfiguration.configuration)) {
            throw new IllegalStateError('Cannot save empty configuration.');
        }
        return this.socketIoService.request<
            ConfigurationCodeRequestInterface,
            string
        >(REQUEST_GET_CONFIGURATION_CODE, {
            productId: productConfiguration.productId,
            configuration: JSON.stringify(productConfiguration.configuration)
        });
    }

    /**
     * Load the configuration-details for the given configurationCode.
     * @param configurationCode The configurationCode to resolve to its configuration.
     */
    loadConfiguration(
        configurationCode: string
    ): Observable<PersistedProductConfiguration> {
        return this.socketIoService
            .request<
                ConfigurationCodeDetailsRequestInterface,
                ConfigurationCodeDetailsResponseInterface
            >(REQUEST_GET_CONFIGURATION_CODE_DETAILS, {
                configurationCode
            })
            .pipe(
                map((response): PersistedProductConfiguration => {
                    const { configurationCodeDetails } = response;
                    if (!configurationCodeDetails.configuration) {
                        throw new IllegalStateError(
                            'Configuration successfully loaded no configuration data contained.'
                        );
                    }

                    let thumbnailUrl: string | undefined;
                    if (configurationCodeDetails.images) {
                        const foundThumbnailImage =
                            configurationCodeDetails.images.find(
                                (image) =>
                                    image.type === 'beautyshot' &&
                                    image.path.indexOf('_0') > -1
                            );
                        if (foundThumbnailImage) {
                            thumbnailUrl =
                                this.staticAssetService.getThumbnailUrl(
                                    foundThumbnailImage.linkPath
                                );
                        }
                    }

                    return {
                        code: configurationCode,
                        productId: configurationCodeDetails.productId,
                        configuration: JSON.parse(
                            configurationCodeDetails.configuration
                        ),
                        createdAt: configurationCodeDetails.createdAt,
                        thumbnailUrl
                    };
                })
            );
    }

    /**
     * Fetch the list of configuration codes that were recently created sorted by
     * date created in descending order.
     */
    getLatestConfigurationCodes$(): Observable<string[]> {
        return this.socketIoService
            .request<void, ConfigurationCodesResponseInterface>(
                REQUEST_GET_CONFIGURATION_CODES,
                undefined
            )
            .pipe(
                map((response): string[] => {
                    if (!response.configurationCodes) {
                        return [];
                    }
                    return response.configurationCodes;
                })
            );
    }
}
