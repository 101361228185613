import { __awaiter } from "tslib";
import { ArgumentsError, ReservationNotFoundError } from '../types/errors';
import { Http } from '../utils/http';
export class ReservationService {
  constructor(serviceOptions, idToken) {
    this.serviceOptions = serviceOptions;
    this.idToken = idToken;
  }
  createReservation(options) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.postWithUserToken(this.serviceOptions, '/api/v1/reservation', this.idToken, options);
      if (response.Status === 400) {
        const error = response.Data;
        throw new ArgumentsError(error.message);
      } else if (response.Status < 200 || response.Status > 299) {
        throw new Error('error creating reservation');
      } else {
        // TODO: Decide how to handle undefined in this case.
        if (!response.Data) {
          throw new Error('error creating reservation');
        }
        return response.Data;
      }
    });
  }
  getReservations() {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.getWithUserToken(this.serviceOptions, '/api/v1/reservation', this.idToken);
      if (response.Status < 200 || response.Status > 299) {
        throw new Error('error getting reservations');
      } else {
        // TODO: Decide how to handle undefined in this case.
        if (!response.Data) {
          throw new Error('error creating reservation');
        }
        return response.Data;
      }
    });
  }
  getReservation(id) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.getWithUserToken(this.serviceOptions, `/api/v1/reservation/${id}`, this.idToken);
      if (response.Status === 404) {
        throw new ReservationNotFoundError();
      } else if (response.Status < 200 || response.Status > 299) {
        throw new Error('error getting reservation');
      } else {
        // TODO: Decide how to handle undefined in this case.
        if (!response.Data) {
          throw new Error('error creating reservation');
        }
        return response.Data;
      }
    });
  }
  deleteReservation(id) {
    return __awaiter(this, void 0, void 0, function* () {
      const response = yield Http.deleteWithUserToken(this.serviceOptions, `/api/v1/reservation/${id}`, this.idToken);
      if (response.Status < 200 || response.Status > 299) {
        throw new Error('error getting reservation');
      }
    });
  }
}