// @ts-nocheck
export class Sdp {
  static getVideoCodecIds(lines) {
    let videoLine = lines.filter(line => line.startsWith('m=video'))[0];
    return videoLine.split(' ').slice(3);
  }
  static getAudioCodecIds(lines) {
    const audioLines = lines.filter(line => line.startsWith('m=audio'));
    if (audioLines.length === 0) {
      return [];
    }
    const audioLine = audioLines[0];
    return audioLine.split(' ').slice(3);
  }
  static mapVideoLine(line, codecIds) {
    if (!line.startsWith('m=video')) {
      return line;
    }
    let videoLine = line.split(' ').slice(0, 3).join(' ');
    videoLine += ' ' + codecIds.join(' ');
    return videoLine;
  }
  static mapAudioLine(line, codecIds) {
    if (!line.startsWith('m=audio')) {
      return line;
    }
    let videoLine = line.split(' ').slice(0, 3).join(' ');
    videoLine += ' ' + codecIds.join(' ');
    return videoLine;
  }
  static isVideoCodecH264(lines, codecId) {
    let codecLines = lines.filter(line => this.belongsToCodec(line, codecId));
    let mapLine = codecLines.filter(line => line.startsWith('a=rtpmap'))[0];
    if (!mapLine) return false;
    let codecType = mapLine.split(' ')[1].split('/')[0];
    if (codecType !== 'H264') {
      return false;
    }
    let mapLine2 = codecLines.filter(line => line.startsWith('a=fmtp'))[0];
    if (!mapLine2) return false;
    let profileLevelId = this.getProfileLevelId(mapLine2);
    if (profileLevelId !== '42e01f' && profileLevelId != '42001f') return false;
    return true;
  }
  static isAudioCodecOpus(lines, codecId) {
    let codecLines = lines.filter(line => this.belongsToCodec(line, codecId));
    let mapLine = codecLines.filter(line => line.startsWith('a=rtpmap'))[0];
    if (!mapLine) return false;
    let codecType = mapLine.split(' ')[1].split('/')[0];
    if (codecType !== 'opus') return false;
    return true;
  }
  static forceH264(sdp, start, min, max) {
    let lines = sdp.split(/\r?\n/);
    let codecIds = this.getVideoCodecIds(lines);
    let matches = [];
    codecIds.forEach(codecId => {
      if (!this.isVideoCodecH264(lines, codecId)) {
        lines = lines.filter(line => !this.belongsToCodec(line, codecId));
      } else {
        matches.push(codecId);
      }
    });
    if (matches.length === 0) throw new Error('your device does not support streaming');
    matches.forEach(codecId => {
      const lastCodecIndex = this.getLastCodecIndex(lines, codecId);
      if (isNaN(lastCodecIndex)) return;
      lines.splice(lastCodecIndex + 1, 0, `a=fmtp:${codecId} x-google-max-bitrate=${max}`);
      lines.splice(lastCodecIndex + 2, 0, `a=fmtp:${codecId} x-google-min-bitrate=${min}`);
      lines.splice(lastCodecIndex + 3, 0, `a=fmtp:${codecId} x-google-start-bitrate=${start}`);
    });
    lines = lines.map(line => this.mapVideoLine(line, matches));
    return lines.join('\n');
  }
  static forceOpus(sdp) {
    let lines = sdp.split(/\r?\n/);
    const codecIds = this.getAudioCodecIds(lines);
    let matches = [];
    codecIds.forEach(codecId => {
      if (!this.isAudioCodecOpus(lines, codecId)) {
        lines = lines.filter(line => !this.belongsToCodec(line, codecId));
      } else {
        matches.push(codecId);
      }
    });
    lines = lines.map(line => this.mapAudioLine(line, matches));
    return lines.join('\n');
  }
  static getLastCodecIndex(lines, id) {
    for (var idx = 0; idx < lines.length; ++idx) {
      if (!this.belongsToCodec(lines[idx], id)) continue;
      while (idx + 1 < lines.length && this.belongsToCodec(lines[idx + 1], id)) idx++;
      return idx;
    }
    return NaN;
  }
  static belongsToCodec(line, id) {
    if (!line.startsWith('a=')) return false;
    return line.startsWith(`a=fmtp:${id} `) || line.startsWith(`a=rtcp-fb:${id} `) || line.startsWith(`a=rtpmap:${id} `);
  }
  static getProfileLevelId(line) {
    let index = line.indexOf('profile-level-id=');
    if (index === -1) return null;
    let plid = line.substring(index + 17);
    plid = plid.split(';')[0];
    return plid;
  }
}