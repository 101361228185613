import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ConfigModel } from '@mhp-immersive-exp/contracts/src/configuration/config-model.interface';
import { ConfigurationRequestPayload } from '@mhp-immersive-exp/contracts/src/configuration/configuration-request.interface';
import {
    ConfigurationResponsePayload,
    ProjectReturn
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { ProjectParameters } from '@mhp-immersive-exp/contracts/src/project-parameters/project-parameters';
import { WebsocketErrorCode } from '@mhp-immersive-exp/contracts/src/websocket/websocket-error-codes';
import { NotImplementedError } from '@mhp/common';

import {
    SocketIOService,
    WebsocketEndpointResponseError
} from '../../communication';
import { ConfigurationResolveMissingAuthorizationError } from '../errors/errors';
import { ProductConfigurationStrategy } from '../product-configuration-strategy.interface';

/**
 * ProductConfigurationCommunicationFacade implementation based on socket-io calls.
 * To be used when application is used in context where engine is available.
 */
@Injectable()
export class ProductConfigurationSocketStrategy<
    P extends ProjectParameters = ProjectParameters,
    PR extends ProjectReturn = ProjectReturn
> implements ProductConfigurationStrategy<P>
{
    constructor(private readonly socketIoService: SocketIOService) {}

    getConfigurationMetadata$(
        productId: string,
        config?: ConfigModel[]
    ): Observable<ConfigurationResponsePayload<PR>> {
        return this.socketIoService
            .request<
                ConfigurationRequestPayload,
                ConfigurationResponsePayload<PR>
            >('getconfiguration', { productId, options: config || [] })
            .pipe(
                catchError((error) => {
                    if (
                        error instanceof WebsocketEndpointResponseError &&
                        error.details?.code ===
                            WebsocketErrorCode.RULER_FAILED_GETTING_CONFIGURATION_AUTHORIZATION
                    ) {
                        throw new ConfigurationResolveMissingAuthorizationError(
                            error?.message,
                            error
                        );
                    }
                    throw error;
                })
            );
    }

    patchConfiguration$(
        productId: string,
        config: ConfigModel[],
        country: string,
        add?: ConfigModel[],
        remove?: ConfigModel[]
    ): Observable<ConfigModel[]> {
        throw new NotImplementedError();
    }
}
