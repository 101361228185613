import LZString from 'lz-string';
import { deepSortObject } from './deep-sort-object.helper';
export const decompressUrl = url => JSON.parse(LZString.decompressFromEncodedURIComponent(url));
export const compressForUri = toCompress => LZString.compressToEncodedURIComponent(JSON.stringify(deepSortObject(toCompress)));
export const compressSessionInfo = ssi => compressForUri(ssi);
export const getSSICamera = camera => {
  const {
    id,
    options
  } = camera || {};
  return {
    id,
    options
  };
};
export const getSSIEnvironment = environment => {
  const {
    id,
    options
  } = environment || {};
  return {
    id,
    options
  };
};
export const getSSIAnimation = animation => {
  const {
    id,
    progress
  } = animation || {};
  return {
    id,
    options: {
      progress
    }
  };
};
export const getSSIAnimations = animations => animations.map(animation => getSSIAnimation(animation));
export const generateSerializableSessionInfoFromApplicationState = (state, meta) => {
  const {
    configurationState: {
      animationStates: animations,
      cameraState: camera,
      environmentState: environment,
      productState: product
    }
  } = state;
  const ssi = {
    environment,
    camera,
    product,
    animations
  };
  if (meta) {
    ssi.meta = meta;
  }
  return ssi;
};
export const generateSerializableSessionInfo = ({
  product,
  animations,
  camera,
  environment,
  meta
}) => {
  const ssi = {
    product,
    environment: getSSIEnvironment(environment),
    camera: getSSICamera(camera),
    animations: getSSIAnimations(animations)
  };
  if (meta) {
    ssi.meta = meta;
  }
  return ssi;
};
/**
 * Print a list of a specified key of an array of objects
 * @param list The array of objects
 * @param key The key to print of each object
 * @param join The string to join each object property by
 * @returns A string of object properties
 */
export const printObjects = (list, key = 'id', join = ', ') => list.map(value => value[key]).join(join);