import { isEmpty, isEqual, isNil, isNumber, isString } from 'lodash-es';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ConfigOption } from '@mhp-immersive-exp/contracts/src/configuration/config-option.interface';
import {
    ConfigurationDetailsRequestPayload,
    REQUEST_GET_CONFIGURATION_DETAILS
} from '@mhp-immersive-exp/contracts/src/configuration/configuration-details-request.interface';
import { ConfigurationDetailsResponsePayload } from '@mhp-immersive-exp/contracts/src/configuration/configuration-details-response.interface';
import { OptionGroup } from '@mhp-immersive-exp/contracts/src/configuration/configuration-response.interface';
import { IllegalStateError } from '@mhp/common';

import { SocketIOService } from '../communication';
import { ConfigurationInfo } from '../product-configuration';
import { ConfigurationConverterMode } from '../product-configuration/configuration-converter';
import { ConfigurationConverterService } from '../product-configuration/configuration-converter.service';
import { EngineSettingsService } from '../settings';

export interface PricingInfo {
    path: string;
    price: number;
}

interface ConfigOptionsWithProductId {
    productId: string;
    options: (string | ConfigOption)[];
}

/**
 * Provides pricing information for interested parties.
 */
@Injectable({
    providedIn: 'root'
})
export class PricingService {
    constructor(
        private socketIOService: SocketIOService,
        private engineSettingsService: EngineSettingsService,
        private configurationConverterService: ConfigurationConverterService
    ) {}

    /**
     * Delegates to EngineSettingsService#getPricingActiveState$()
     */
    getPricingActiveState$() {
        return this.engineSettingsService.getPricingActiveState$();
    }

    /**
     * Fetches the pricing information for the given productId and options
     * @param productId The productId the pricing information should relate to.
     * @param options The options for which pricing information should be fetched.
     */
    getPricingInfoForOptions(
        productId: string,
        options: (string | ConfigOption)[]
    ): Observable<Map<string, PricingInfo>> {
        return this.socketIOService
            .request<
                ConfigurationDetailsRequestPayload,
                ConfigurationDetailsResponsePayload
            >(REQUEST_GET_CONFIGURATION_DETAILS, {
                productId,
                options
            })
            .pipe(
                map((response) =>
                    response.details.map((currentEntry): PricingInfo => {
                        const optionKeys = this.getKeys(currentEntry.option);

                        if (optionKeys.length > 1) {
                            throw new IllegalStateError(
                                'Got multiple option keys referring to a single pricing information.'
                            );
                        }

                        let price = 0;
                        if (currentEntry.price) {
                            if (
                                !isNil(currentEntry.price) &&
                                !isString(currentEntry.price) &&
                                !isNumber(currentEntry.price)
                            ) {
                                throw new IllegalStateError(
                                    `Unexpected type for price: ${currentEntry.price}`
                                );
                            }
                            try {
                                price = isString(currentEntry.price)
                                    ? parseFloat(currentEntry.price)
                                    : currentEntry.price;
                            } catch (error) {
                                throw new IllegalStateError(
                                    `Failed parsing price to float: ${currentEntry.price}`,
                                    error
                                );
                            }
                        }

                        return {
                            path: optionKeys[0],
                            price
                        };
                    })
                ),
                map((pricingInfos) =>
                    pricingInfos.reduce((pricingInfosMap, pricingInfo) => {
                        if (pricingInfosMap.has(pricingInfo.path)) {
                            throw new IllegalStateError(
                                `Got duplicate entry for pricing-infos with path [${pricingInfo.path}]`
                            );
                        }

                        pricingInfosMap.set(pricingInfo.path, pricingInfo);

                        return pricingInfosMap;
                    }, new Map<string, PricingInfo>())
                )
            );
    }

    getPricingInfoForConfigurationMeta(
        productId: string,
        configMeta: OptionGroup[]
    ): Observable<Map<string, PricingInfo>> {
        return this.getPricingInfoForOptions(
            productId,
            this.mapToEndpointInput(configMeta)
        );
    }

    /**
     * Allows to create a stream which emits the pricing info
     * for given ConfigurationMetadata input stream.
     * This allows the service to apply internal optimizations on when it is
     * required to re-fetch pricing information from the backend.
     *
     * Note that no pricing information is emitted in case pricing is not active
     * according to EngineSettingsService#getPricingActiveState$()
     *
     * @param configMeta$ A stream emitting the currently active ConfigurationMetadata
     */
    getPricingInfoForConfigurationMeta$(
        configMeta$: Observable<ConfigurationInfo<OptionGroup> | undefined>
    ): Observable<ReadonlyMap<string, PricingInfo> | undefined> {
        return combineLatest([
            this.engineSettingsService.getPricingActiveState$(),
            configMeta$.pipe(
                map((configMeta): ConfigOptionsWithProductId | undefined => {
                    if (!configMeta) {
                        return undefined;
                    }
                    return {
                        productId: configMeta.productId,
                        options: this.mapToEndpointInput(
                            configMeta.configuration
                        )
                    };
                }),
                distinctUntilChanged(isEqual)
            )
        ]).pipe(
            switchMap(([pricingActive, configOptionsWithProductId]) => {
                if (!pricingActive || !configOptionsWithProductId) {
                    return of(undefined);
                }
                return this.getPricingInfoForOptions(
                    configOptionsWithProductId.productId,
                    configOptionsWithProductId.options
                );
            })
        );
    }

    private getKeys(option: string | ConfigOption): string[] {
        if (isEmpty(option)) {
            return [];
        }

        if (isString(option)) {
            return [option];
        }

        const keys: string[] = [];
        for (const optionKey of Object.keys(option)) {
            const nestedOptions = option[optionKey];
            Object.keys(nestedOptions).forEach((nestedOptionKey) => {
                keys.push(
                    `${optionKey}-${nestedOptionKey}-${nestedOptions[nestedOptionKey]}`
                );
            });
        }
        return keys;
    }

    private mapToEndpointInput(
        configMeta: OptionGroup[]
    ): (string | ConfigOption)[] {
        return this.configurationConverterService.convertToConfigurationFormat(
            configMeta,
            ConfigurationConverterMode.COMPLETE
        );
    }
}
