import { InteractionType } from '@azure/msal-browser';
import { Environment } from '@mhp-immersive-exp/contracts/src/environment/environment.interface';
import { AMLEnvironmentMeta } from '@mhp/aml-shared/product-data/aml-environment-meta.interface';
import { GoogleTagManagerEnvironmentSpec } from '@mhp/ui-shared-services';
import { CutStreamLimitsConfig } from '@mhp/ui-shared-services/streaming/cut-event-handling/sliding-time-window-based-streaming-event-handler';

import { VisualizationMode } from '../app/configuration';
import { ImageOnDemandResolution } from '../app/configuration/static-renderer/static-renderer.service';
import { defaultsDeepCustom } from './environment-helper';

const AML_BACKEND_BASE_URL =
    'https://aml-infor-preview-4q6zszk7ge7yhcs.api-services.astonmartin.com';

const DEALER_AUTHORITY_DOMAIN_PREFIX = 'amlb2ctest';

const IOD_SRCSET_RESOLUTIONS: ImageOnDemandResolution[] = [960, 2048];

export const environmentUrlFactory = (amlBackendBaseUrl) => ({
    assets: {
        url: `${amlBackendBaseUrl}/static`
    },
    imageProxy: {
        url: `${amlBackendBaseUrl}/iod`
    },
    ruler: {
        url: `${amlBackendBaseUrl}/rules`
    },
    productData: {
        url: `${amlBackendBaseUrl}/models`
    },
    dataProxy: {
        url: `${amlBackendBaseUrl}/data`
    },
    pdfWebservice: {
        url: `${amlBackendBaseUrl}/pdf`
    },
    urlShortener: {
        url: `${amlBackendBaseUrl}/shortener`
    }
});

export const defaultEnvironmentConfig = defaultsDeepCustom(
    {
        appConfig: environmentUrlFactory(AML_BACKEND_BASE_URL)
    },
    {
        production: false,
        stage: 'development',
        appConfig: {
            baseUrls: {
                publicBaseUrl: `${window.location.protocol}//${window.location.hostname}:4201`,
                dealerBaseUrl: `${window.location.protocol}//${window.location.hostname}:4200`
            },
            bugsnag: {
                apiKey: '438d22ed4088c6fa9f6b73330c5b9972'
            },
            oneTrust: {
                loadAutoBlockScript: true,
                domainScriptId: '85ef5428-df90-4621-ade2-e6a886cdf307-test'
            },
            dealer: {
                dealerBuild: false,
                forceAuthentication: false,
                clientId: '84c542d5-73e5-444b-a7cb-dcd9446734ea',
                authorities: {
                    signIn: `https://${DEALER_AUTHORITY_DOMAIN_PREFIX}.b2clogin.com/${DEALER_AUTHORITY_DOMAIN_PREFIX}.onmicrosoft.com/b2c_1a_signin`
                },
                authorityDomain: `${DEALER_AUTHORITY_DOMAIN_PREFIX}.b2clogin.com`,
                redirectUrl: 'http://localhost:4200',
                interactionType: <
                    InteractionType.Popup | InteractionType.Redirect
                >InteractionType.Redirect,
                // routes that should be secured / a bearer-token should be added to. Currently, only openid-scope is supported. If any other scopes are required, this has to be enhanced
                protectedResources: [
                    '.*/rules/.*', // ruler-service delivers pricing-information for dealers only
                    '.*/models/.*', // product-data-service delivers pricing-information for dealers only
                    '.*/cpq/.*', // order-management-service to configure orders for dealers only
                    '.*/ion/.*', // order-management-service to configure orders for dealers only
                    '.*/dcm/.*', // order-management-service to show saved orders for dealers only
                    '.*/data/.*', // data-proxy allows certain actions for dealers only
                    '.*/pdf/.*' // data-proxy allows certain actions for dealers only
                ],
                skipLoginStatusNotifications: false,
                orderManagementDowntimes: <{ from: string; to: string }[]>[
                    {
                        from: '2024-11-15T23:00:00.000Z',
                        to: '2024-11-16T02:00:00.000Z'
                    }
                ]
            },
            brandStore: {
                brandStoreBuild: false,
                overrideHubUrl: <string | undefined>undefined,
                overrideSocketIoRoomId: <string | undefined>undefined,
                // how many times can a heartbeat-request be unanswered before assuming the engine is not reachable
                engineHeartbeatMaxFailures: 1,
                mirrorMode: {
                    interiorMirrorModeCameraFallbackId: 'BS_Config_Int_1_LH' // camera-id used as a fallback in case no defaultInt camera is defined via product-data
                }
            },
            defaultProductId: 'AM614',
            engineHeartbeatInterval: 6000,
            configuration: {
                allowDebugPanel: true,
                blockLocalConfigurationChangesWhileResolveTimeout: 100,
                // use uppercase for following identifiers
                identifierPersonalisation: 'PERSONALISATION',
                identifierAccessories: 'ACCESSORIES',
                identifierExterior: 'EXTERIOR',
                identifierInterior: 'INTERIOR',
                identifierInteriorOptions: 'INTERIOR_OPTIONS',
                identifierRootLevelEditions: 'EDITIONS',
                identifierSummary: 'SUMMARY',
                identifierSummaryService: 'SERVICE_PLAN',
                identifierSummaryWarranty: 'EXTENDED_WARRANTY',
                identifierSummaryPricing: 'PRICING',
                identifierInteriorWithEnvironmentSelection: 'INTERIOR',
                identifierEnvironmentSelection: 'ENVIRONMENTS',
                identifierOptionCodeGasGuzzlerTax: 'GAS',
                identifierStitchingTypeSelection: [
                    'STITCHING',
                    'STITCHING_AND_WELT'
                ],
                identifierStitchingListCodes: ['SU', 'WT'],
                identifierLiveries: 'LIVERIES',
                identifierPaint: 'PAINT',
                identifierInteriorPrimary: 'TU',
                identifierInteriorSecondary: 'TL',
                // option metadata fields
                optionMetadata: {
                    thumbnailPrefix: 'thumbnailPrefix'
                },
                interiorEnvironment: {
                    camera: 'BP_Interior_Interiorselection',
                    degreeStep: 5
                },
                heritageEnvironmentId: 'env_110Year_Anniversary',
                silverstoneEnvironmentId: 'Silverstone',
                skipShowEnvironmentInfoDuration: 48 * 60 * 60 * 1000, // how long should the environment-info dialog not be displayed to the user?
                // optional callback to allow filtering environments available to the UI
                environmentFilter: <
                    | ((
                          environment: Environment<AMLEnvironmentMeta>
                      ) => boolean)
                    | undefined
                >undefined,
                nightModeDisabledForEnvironments: <string[]>[
                    'Rainbow Environment',
                    'ENV_ExtentionBS',
                    'Plaza'
                ],
                dayModeDisabledForEnvironments: <string[]>['Las Vegas'],
                nightModeDefaultForEnvironments: <string[]>[],
                // derivatives for which the user should get a notification that configurations might no longer be available
                outdatedDerivatives: [],
                imgComparisonEnvironmentId: 'Studio'
            },
            visualization: {
                defaultVisualizationMode: VisualizationMode.BASIC,
                turntable: {
                    camera: 'Turntable',
                    degreeStep: 20
                },
                defaultCinematic: 'BP_Cine_Beautyshots',
                defaultEnvironmentThumbnailCamera:
                    'BS_Config_Ext_Environment_Image', // fallback environment camera in case no thumbCam is defined
                publicStreamAvailable: true,
                countriesPublicStreamAvailable: [],
                // allows to explicitly override the origin for cinematic-download-URLs provided by the engine. In case it's set to a non-string value, the override will be based on document.location information
                cinematicDownloadOriginOverride: <string | false>(
                    'https://aml-stage-preview-4q6zszk7ge7yhcs.api-services.astonmartin.com'
                ),
                // environments which are not supported for IOD renderings
                iodNonSupportingEnvironments: <
                    {
                        id: string;
                        fallbackEnvironment: string;
                        fallbackCamera?: string;
                    }[]
                >[
                    {
                        id: 'VRRoom',
                        fallbackEnvironment: 'Studio',
                        fallbackCamera: 'BS_Config_Ext_2'
                    }
                ]
            },
            stream: {
                url: 'https://aml-dev.gorillastreaming.com',
                appEnvId: '8a3c85be-bfda-483c-af29-3b9b3e064158',
                apiKey: '149be053-04c1-45b3-8a33-2bcd5dcbce1a',
                resetTo2dOnConfigurationLeave: false,
                cutStreamLimits: {
                    noFramesRatio: 0.8, // cut connection when no more frames where received during x percent of the observed time-window
                    // bitrate: 1000, // cut connection if bitrate is lower than X kbit. As advised by MW, currently not in use
                    latency: 800 // cut connection if latency is > X. MW recommended 300ms here but this seems to be to restrictive as we experienced during test-sessions
                } as CutStreamLimitsConfig,
                // this is the interval with which MW internally check the stats. Asked Stefan Koch to make this configurable
                internalConnectionStatsCheckInterval: 500,
                buildCutStreamLimitMeanValuesOverTime: 5000,
                checkForCutStreamLimitsInterval: 2000,
                waitForInitialEngineResponseAfterConnectTimeout: 10000,
                waitForUserActionTimeoutWhenStreamBecomesAvailable: 10000,
                userInactivityTimeout: 10 * 60 * 1000, // the amount of time during which no user-interaction must occur to show a timeout-notification to the user
                waitForUserActionTimoutOnUserInactivity: 60000, // how long to wait for a user-decision after inactivity has been detected
                sessionUsageSessionClientValue: 'public' // value used when an ad-hoc streaming-session is started from the configurator,
            },
            assets: {
                splitterSrcsetSizes: [60, 120, 240, 480],
                optionSrcsetSizes: [180, 360, 675, 1350],
                cameraSrcsetSizes: [140, 280, 560], // keep for brandstore
                environmentSrcsetSizes: [140, 280, 560], // keep for brandstore
                animationSrcsetSizes: [140, 280, 560], // keep for brandstore
                previewThumbnailSizes: <ImageOnDemandResolution[]>[
                    240, 360, 560, 960, 2048
                ],
                personalisationAndAccessoriesSrcsetSizes: [
                    400, 800, 1200, 1600
                ],
                interiorSrcsetSizes: [1024, 2048, 4096],
                modelThumbnailsSrcsetSizes: [120, 240, 1000, 2000],
                optionIodThumbnailsDefaultEnvironment: 'Studio'
            },
            i18n: {
                translationFileUrlFactory: (lang: string) =>
                    `assets/i18n/${lang}.json`
            },
            imageProxy: {
                srcsetResolutions: IOD_SRCSET_RESOLUTIONS
            },
            recaptcha: {
                siteKey: '6LdhjboaAAAAAMUeK1aC1H1lxY8XneaPWKjhkaju'
            },
            googleTagManager: {
                trackingId: 'GTM-TF2CBVX',
                environmentSpec: <GoogleTagManagerEnvironmentSpec | null>{
                    gtmAuth: 'MJVr8z6cOWMUH4cP3tb0hA',
                    gtmPreview: 'env-410',
                    gtmCookiesWin: 'x'
                }
            },
            salesforceDataCloud: {
                beaconUrl:
                    'https://cdn.c360a.salesforce.com/beacon/c360a/86a389b3-30b5-49ba-91cc-40e13e4288ea/scripts/c360a.min.js'
            },
            one2one: {
                dealerReservationKeyQueryParamName: 'reservationKey',
                confirmSessionConfigurationQueryParamName: 'configuration',
                confirmSessionPreferredDateQueryParamName: 'preferredDate',
                startSessionMinutesDiff: 5,
                bookSessionNowMinutesInFuture: 1,
                showCopySessionLinksDialog: false
            },
            crm: {
                salesforce: {
                    referralWebForm: 'https://configurator.astonmartin.com/',
                    gdprFormReference: 'Configurator Lead',
                    transactionKeyQueryParamName: 'transactionKey'
                }
            },
            errors: {
                appendRootErrorMessageInUiNotification: true
            },
            featureToggles: {
                one2oneBuyingExperience: false,
                configurationAnimations: true,
                modelSelectVideos: true,
                downloadCinematics: true,
                modelSelectEngineSounds: true,
                renderOptionIodThumbnails: true,
                enableFinancialServices: true,
                // see https://mhpimmersive.atlassian.net/browse/AMIF-694
                enableAutoProvideConfigCodeOnLeaveConfiguration: true,
                // see https://mhpimmersive.atlassian.net/browse/AMIF-695
                enableResumeConfiguration: true,
                enableIncludePricingInPdf: true
            }
        }
    }
);
