import { isArray } from 'lodash-es';
import { EMPTY, Observable, combineLatest, iif, mergeMap, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { RRP_COUNTRIES_SET } from '@mhp/aml-shared/country-mapping/rrp-countries';
import { getDerivativeStaticInfo } from '@mhp/aml-shared/derivate-mapping/derivate-mapping';
import {
    ApplicationNavigationArea,
    DealerInfoService,
    PricingData,
    PricingType
} from '@mhp/aml-ui-shared-services';
import { MemoizeObservable, lazyShareReplay } from '@mhp/common';
import {
    AlternateDataSource,
    ApplicationStateService,
    L10nService
} from '@mhp/ui-shared-services';
import { StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '../../../environments/environment';
import { ConfigurationSessionInfoService } from '../../configuration/session-info/configuration-session-info.service';
import { NavigationStateService } from '../../navigation/navigation-state.service';
import { LocalApplicationState } from '../../state/local-application-state.interface';
import { setPricingType } from '../state/actions/dealer-state.actions';
import {
    selectPricingSelectionSource,
    selectPricingType
} from '../state/selectors/dealer-state.selectors';

const STORAGE_KEY_PRICING_TYPE = 'AML_PRICING_TYPE';

@Injectable({
    providedIn: 'root'
})
export class PricingService {
    // an optional pricing-source emitting override-prices
    private readonly alternatePricingSource = new AlternateDataSource<
        PricingData | undefined
    >();

    constructor(
        private readonly storageMap: StorageMap,
        private readonly applicationStateService: ApplicationStateService<LocalApplicationState>,
        private readonly l10nService: L10nService,
        private readonly configurationSessionInfoService: ConfigurationSessionInfoService,
        private readonly navigationStateService: NavigationStateService,
        private readonly dealerInfoService: DealerInfoService
    ) {
        this.initSyncPricingTypeToLocalStorage();
    }

    /**
     * Emit true / false indicating whether pricing should be available for PDFs or not.
     */
    @MemoizeObservable()
    isPdfPricingAvailable$(): Observable<boolean> {
        if (!environment.appConfig.featureToggles.enableIncludePricingInPdf) {
            return of(false);
        }
        return this.isRRPAvailableForDealer$();
    }

    /**
     * Emit true / false indicating whether the dealer has RRP-pricing support.
     */
    @MemoizeObservable()
    isRRPAvailableForDealer$(): Observable<boolean> {
        return this.getAvailablePricingTypes$().pipe(
            map((pricingTypes) => pricingTypes.indexOf('RRP') > -1)
        );
    }

    /**
     * Emit the available pricing types.
     */
    @MemoizeObservable()
    getAvailablePricingTypes$(productId?: string): Observable<PricingType[]> {
        return this.getAvailablePricingTypesUsingReferenceCountry$(productId);
    }

    /**
     * Emit the available pricing types, optionally taking a specific productId
     * and a referenceCountry into account.
     * @param productId Optional. Use dedicated productId to check against instead of the active productId when in context of the configuration area.
     * @param referenceCountry Optional. Use a referenceCountry that should be used to check available prices against. Might be ignored in
     *                         case the dealer is non-internal, as in this case, the dealers country will take preference.
     */
    getAvailablePricingTypesUsingReferenceCountry$(
        productId?: string,
        referenceCountry?: string
    ): Observable<PricingType[]> {
        const pricingTypeReferenceCountry$: Observable<string | undefined> =
            combineLatest([
                iif(
                    () => !!referenceCountry,
                    of(referenceCountry),
                    this.l10nService.getActiveCountry$()
                ),
                this.dealerInfoService.getActiveDealerInfo$()
            ]).pipe(
                map(([activeCountry, activeDealerInfo]) => {
                    if (!activeCountry || !activeDealerInfo) {
                        return undefined;
                    }

                    // in case it's an internal dealer, the dealer may operate freely based on the currently active country
                    if (activeDealerInfo.isInternal) {
                        return activeCountry;
                    }
                    // in case it's a regular dealer, he is restricted to the country he's bound to
                    return activeDealerInfo.countryISO2;
                }),
                // in case we stumble upon an error provide no reference country
                catchError(() => of(undefined))
            );

        return combineLatest([
            pricingTypeReferenceCountry$,
            this.configurationSessionInfoService.getActiveProductId$(),
            this.navigationStateService.getActiveApplicationNavigationArea$()
        ]).pipe(
            map(
                ([
                    pricingTypeReferenceCountry,
                    activeProductId,
                    activeNavigationArea
                ]): PricingType[] => {
                    if (!environment.appConfig.dealer.dealerBuild) {
                        return ['HIDE'];
                    }

                    if (
                        productId ||
                        (activeProductId &&
                            activeNavigationArea ===
                                ApplicationNavigationArea.CONFIGURATION)
                    ) {
                        try {
                            const derivativeStaticInfo =
                                getDerivativeStaticInfo(
                                    (productId || activeProductId) as string
                                );
                            if (
                                derivativeStaticInfo.forceHidePricing ===
                                    true ||
                                (isArray(
                                    derivativeStaticInfo.forceHidePricing
                                ) &&
                                    (!pricingTypeReferenceCountry ||
                                        derivativeStaticInfo.forceHidePricing.indexOf(
                                            pricingTypeReferenceCountry
                                        ) > -1))
                            ) {
                                return ['HIDE'];
                            }
                        } catch (error) {
                            console.warn(
                                `Failed getting derivative info for product ${
                                    productId || activeProductId
                                } while determining available pricing-types.`
                            );
                        }
                    }
                    if (
                        !pricingTypeReferenceCountry ||
                        !RRP_COUNTRIES_SET.has(pricingTypeReferenceCountry)
                    ) {
                        return ['DNP', 'HIDE'];
                    }
                    return ['DNP', 'RRP', 'HIDE'];
                }
            ),
            lazyShareReplay()
        );
    }

    /**
     * If pricing should be shown either
     * - for the currently active derivative when configuration navigation-section is active
     * - for a given product-id
     * @param productId An optional productId that should be used for checking pricing availability. Without providing this, the currently active productId is used.
     */
    shouldShowPricing$(productId?: string): Observable<boolean> {
        return this.getAvailablePricingTypes$(productId).pipe(
            map(
                (availablePricingTypes) =>
                    availablePricingTypes.length > 1 ||
                    availablePricingTypes.indexOf('HIDE') === -1
            )
        );
    }

    /**
     * Emit the currently active pricing-type.
     */
    getActivePricingType$() {
        return this.applicationStateService
            .getLocalState()
            .pipe(selectPricingType);
    }

    /**
     * Register an observable stream providing alternate pricing information that is
     * used in preference when calculating prices for options.
     * @param pricingSource$
     * @return Cleanup-callback to remove registered provider.
     */
    registerAlternatePricingSource(
        pricingSource$: Observable<PricingData | undefined>
    ) {
        return this.alternatePricingSource.registerAlternateDataSource(
            pricingSource$
        );
    }

    /**
     * Get access to a possibly registered alternative pricing data stream.
     */
    @MemoizeObservable()
    getAlternatePricingData$(): Observable<PricingData | undefined> {
        return this.alternatePricingSource.getAlternateData$();
    }

    private initSyncPricingTypeToLocalStorage() {
        combineLatest([
            this.storageMap.watch(STORAGE_KEY_PRICING_TYPE, {
                type: 'string'
            }),
            this.getAvailablePricingTypes$()
        ]).subscribe(([pricingType, availablePricingTypes]) => {
            if (
                !pricingType ||
                availablePricingTypes.indexOf(<PricingType>pricingType) === -1
            ) {
                return;
            }
            this.applicationStateService.dispatch(
                setPricingType({
                    pricingType: <PricingType>pricingType,
                    settingSource: 'SYSTEM'
                })
            );
        });

        combineLatest([
            this.applicationStateService
                .getLocalState()
                .pipe(selectPricingType),
            this.applicationStateService
                .getLocalState()
                .pipe(selectPricingSelectionSource)
        ])
            .pipe(
                mergeMap(([pricingType, selectionSource]) => {
                    if (selectionSource === 'SYSTEM') {
                        return EMPTY;
                    }
                    return this.storageMap.set(
                        STORAGE_KEY_PRICING_TYPE,
                        pricingType,
                        {
                            type: 'string'
                        }
                    );
                })
            )
            .subscribe();
    }
}
