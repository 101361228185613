import deepLock from 'deep-lock';

import { CloudAccountEnv } from '@mhp-immersive-exp/contracts/src/generic/cloud-account-env';

import { MENAT_COUNTRIES } from '../country-mapping/territories';
import {
    CAP_CODES,
    CONFIGURATOR_DERIVATES,
    LEGACY_MODEL_FAMILY_MAPPING,
    MODELCODES,
    VISUALIZER_DERIVATES
} from './derivates';
import {
    DerivativeInteriorEnvironmentDefinition,
    DerivativeInteriorEnvironmentHaloSpec,
    DerivativeStaticInfo,
    ModelStaticInfo
} from './derivative-mapping.interfaces';

const getDerivativeInteriorEnvironmentDefaultSelections: (modelId: string) => {
    haloSpecs: DerivativeInteriorEnvironmentHaloSpec;
    definition: DerivativeInteriorEnvironmentDefinition[];
} = (function () {
    const DERIVATIVE_INTERIOR_ENVIRONMENT_DEFAULT_SELECTIONS: {
        [modelId: string]: {
            haloSpecs: DerivativeInteriorEnvironmentHaloSpec;
            definition: DerivativeInteriorEnvironmentDefinition[];
        };
    } = {
        AM5: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWK2']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L707' } }],
                TWK2: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK3: [{ TU: { TU: 'L700' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK5: [{ TU: { TU: 'L833' } }],
                TWK6: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWK7: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        },
        AM5x2: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWK2']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Comfort',
                    defaultRelatedOption: 'TWK5',
                    relatedOptions: ['TWK5', 'TWK6']
                },
                {
                    name: 'Inspire Sport',
                    defaultRelatedOption: 'TWKC',
                    relatedOptions: ['TWKC', 'TWKD', 'TWKE'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L708' } }],
                TWK2: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK3: [{ TU: { TU: 'L700' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK5: [{ TU: { TU: 'L833' } }],
                TWK6: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWKC: [{ TU: { TU: 'L833' } }],
                TWKE: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWKD: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        },
        AM6: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWK2']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire',
                    defaultRelatedOption: 'TWK7',
                    relatedOptions: ['TWK5', 'TWK7'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L707' } }],
                TWK2: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK3: [{ TU: { TU: 'L700' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK5: [{ TU: { TU: 'L833' } }],
                TWK7: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        },
        AM6x4: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWKH']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK3',
                    relatedOptions: ['TWK3', 'TWKJ']
                },
                {
                    name: 'Inspire',
                    defaultRelatedOption: 'TWK5',
                    relatedOptions: ['TWK5', 'TWK7']
                },
                {
                    name: 'Inspire Sport',
                    defaultRelatedOption: 'TWKC',
                    relatedOptions: ['TWKC', 'TWKD'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L700' } }],
                TWK3: [{ TU: { TU: 'L700' } }],
                TWK5: [{ TU: { TU: 'L833' } }, { WT: { WT: 'L820' } }],
                TWK7: [
                    { TU: { TU: 'L802' } },
                    { TL: { TL: 'L833' } },
                    { WT: { WT: 'L820' } }
                ],
                TWKC: [{ TU: { TU: 'L833' } }, { WT: { WT: 'L820' } }],
                TWKD: [
                    { TU: { TU: 'L802' } },
                    { TL: { TL: 'L833' } },
                    { WT: { WT: 'L820' } }
                ],
                TWKH: [{ TU: { TU: 'L700' } }],
                TWKJ: [{ TU: { TU: 'L700' } }]
            }
        },
        AM7: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWK2']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6', 'TWK7'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L707' } }],
                TWK2: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK3: [{ TU: { TU: 'L700' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK5: [{ TU: { TU: 'L833' } }],
                TWK6: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWK7: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        },
        AM7x9: {
            definition: [
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Sport',
                    defaultRelatedOption: 'TWKD',
                    relatedOptions: ['TWKC', 'TWKD', 'TWKE'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK3: [{ TU: { TU: 'L700' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWKC: [{ TU: { TU: 'L833' } }],
                TWKD: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWKE: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        },
        AM8: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWK2']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Comfort',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6', 'TWK7'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L707' } }],
                TWK2: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK3: [{ TU: { TU: 'L707' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK5: [{ TU: { TU: 'L833' } }],
                TWK6: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWK7: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        },
        AM8_MY23: {
            definition: [
                {
                    name: 'Create',
                    defaultRelatedOption: 'TWK1',
                    relatedOptions: ['TWK1', 'TWK2']
                },
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Comfort',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6', 'TWK7'],
                    default: true
                }
            ],
            haloSpecs: {
                TWK1: [{ TU: { TU: 'L707' } }],
                TWK2: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK3: [{ TU: { TU: 'L707' } }],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L707' } }],
                TWK5: [{ TU: { TU: 'L833' } }],
                TWK6: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }],
                TWK7: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L833' } }]
            }
        }
    };

    return (modelId: string) => {
        const foundInfo =
            DERIVATIVE_INTERIOR_ENVIRONMENT_DEFAULT_SELECTIONS[modelId];
        if (!foundInfo) {
            throw new Error(
                `MISSING INTERIOR ENVIRONMENT DEFAULT SELECTIONS FOR MODEL ${modelId}`
            );
        }
        return foundInfo;
    };
})();

/**
 * Add derivative-information here.
 */
const DERIVATIVE_STATIC_INFO = <
    {
        [derivativeId: string]: DerivativeStaticInfo;
    }
>deepLock(<
    {
        [derivativeId: string]: DerivativeStaticInfo;
    }
>{
    AM003: {
        sortOrder: 999,
        effectiveDate: '2020-01-01T00:00:00.000Z',
        haloSpec: [
            '3129',
            'AU18',
            'BBDG',
            'CB31',
            'EF02',
            'EHS0',
            'EX24',
            'EXB0',
            'FG53',
            'FMTN',
            'GC30',
            'GPCE',
            'IMC0',
            'IMD0',
            'MSC4',
            'PA40',
            'PIR0',
            'PRG0',
            'PSC2',
            'RL22',
            'SB29',
            'SF30',
            'SP60',
            'STG2',
            'SUTA',
            'TR08',
            'TRU2',
            'TRW2',
            'TWKT',
            'TY08',
            'WLAC',
            { SC: { SC: 'RL09' } },
            { SL: { SL: 'TJ30' } },
            { SU: { SU: 'TJ30' } },
            { TL: { TL: 'L814' } },
            { TU: { TU: 'L814' } }
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs: {
                TWKP: [{ TL: { TL: 'RL80' } }, { TU: { TU: 'RL09' } }],
                TWKQ: [{ TL: { TL: 'RL85' } }, { TU: { TU: 'RL09' } }],
                TWKR: [{ TL: { TL: 'RL80' } }, { TU: { TU: 'L838' } }],
                TWKS: [{ TL: { TL: 'RL85' } }, { TU: { TU: 'L838' } }],
                TWKT: [{ TL: { TL: 'L838' } }, { TU: { TU: 'L838' } }],
                TWKU: [{ TL: { TL: 'L830' } }, { TU: { TU: 'L838' } }]
            },
            definition: [
                {
                    name: 'Alcantara',
                    default: true,
                    relatedOptions: ['TWKP', 'TWKQ'],
                    defaultRelatedOption: 'TWKP'
                },
                {
                    name: 'Alcantara & Semi Aniline Leather',
                    relatedOptions: ['TWKR', 'TWKS'],
                    defaultRelatedOption: 'TWKR'
                },
                {
                    name: 'Semi Aniline Leather',
                    relatedOptions: ['TWKT', 'TWKU'],
                    defaultRelatedOption: 'TWKT'
                }
            ]
        },
        countryExcludes: [],
        hasQOptions: false,
        hidePerformanceStats: true,
        forceHidePricing: true,
        isAuthorizedOnly: false,
        showRenderApi: true
    },
    AM370: {
        sortOrder: 190,
        effectiveDate: '2020-01-01T00:00:00.000Z',
        haloSpec: [
            '3081',
            'GPB6',
            'FG17',
            'TRW6',
            'TRU7',
            'EEP3',
            'PNLM',
            'WR7S',
            'BDT2',
            'CB31',
            'TLP5',
            'PRG0',
            'TWK5',
            'PA24',
            { TU: { TU: 'L845' } },
            'STG3',
            { HO: { HO: 'L845' } },
            { HL: { HL: 'L845' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            { SC: { SC: 'L845' } },
            'CSI4',
            'SUT5',
            'TXC2',
            'SF30',
            'AU18',
            'BBD0',
            'FMTM'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs: {
                TWK3: [{ TU: { TU: 'L838' } }],
                TWK4: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L830' } }],
                TWK5: [{ TU: { TU: 'L845' } }],
                TWK6: [{ TU: { TU: 'L830' } }, { TL: { TL: 'L800' } }],
                TWK7: [{ TU: { TU: 'L802' } }, { TL: { TL: 'L805' } }],
                TWKC: [{ TU: { TU: 'L838' } }],
                TWKE: [{ TU: { TU: 'L810' } }, { TL: { TL: 'L838' } }],
                TWKY: ['PA13', { TU: { TU: 'L838' } }],
                TWKZ: ['PA13', { TU: { TU: 'L838' } }, { TL: { TL: 'L810' } }]
            },
            definition: [
                {
                    name: 'Accelerate',
                    relatedOptions: ['TWK3', 'TWK4'],
                    defaultRelatedOption: 'TWK3'
                },
                {
                    name: 'Inspire',
                    default: true,
                    relatedOptions: ['TWK5', 'TWK7', 'TWK6'],
                    defaultRelatedOption: 'TWK5'
                },
                {
                    name: 'Inspire Sport',
                    relatedOptions: ['TWKC', 'TWKE'],
                    defaultRelatedOption: 'TWKC'
                },
                {
                    name: 'Performance',
                    relatedOptions: ['TWKY', 'TWKZ'],
                    defaultRelatedOption: 'TWKY'
                }
            ]
        },
        countryExcludes: [],
        hasQOptions: false,
        hidePerformanceStats: false,
        forceHidePricing: MENAT_COUNTRIES,
        isAuthorizedOnly: false,
        showRenderApi: true,
        deploymentEnvironmentAvailability: CloudAccountEnv.PRODUCTION
    },
    AM572: {
        effectiveDate: '2000-01-01T00:00:00.000Z',
        sortOrder: 10,
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3018',
            'TRW0',
            'TRU5',
            'FG17',
            'PNL4',
            'WRB8',
            'BDT2',
            'CB04',
            'TLP7',
            'PRG1',
            'PA13',
            'TWKD',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L833' } },
            { HL: { HL: 'L838' } },
            { HO: { HO: 'L838' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'SUT5',
            'VRE5',
            'SF28',
            'BBD0',
            'STG1'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        useDerivativeSpecificLogo: false,
        hasQOptions: false,
        showRenderApi: true,
        followUpModelYearCode: 'AM574'
    },
    AM574: {
        effectiveDate: '2020-01-01T00:00:00.000Z',
        sortOrder: 10,
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3018',
            'TRW0',
            'TRU5',
            'FG17',
            'PNL4',
            'WRB8',
            'BDT2',
            'CB04',
            'TLP7',
            'PRG1',
            'PA13',
            'TWKD',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L833' } },
            { HL: { HL: 'L838' } },
            { HO: { HO: 'L838' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'SUT5',
            'VRE5',
            'SF28',
            'BBD0',
            'STG1'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        useDerivativeSpecificLogo: false,
        hasQOptions: false,
        showRenderApi: true,
        followUpModelYearCode: 'AM575'
    },
    AM575: {
        effectiveDate: '2020-01-01T00:00:00.000Z',
        sortOrder: 10,
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3018',
            'TRW0',
            'TRU5',
            'FG17',
            'PNLM',
            'WRB8',
            'BDT3',
            'CB04',
            'TLP7',
            'PRG1',
            'PA15',
            'TWKD',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L833' } },
            { HL: { HL: 'L838' } },
            { HO: { HO: 'L838' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'SUT5',
            'VRE5',
            'SF28',
            'BBD0',
            'STG1',
            'TSP4'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        useDerivativeSpecificLogo: false,
        hasQOptions: false,
        showRenderApi: true
    },
    AM577: {
        effectiveDate: '2030-01-01T00:00:00.000Z',
        sortOrder: 10,
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3018',
            'TRW0',
            'TRU5',
            'FG17',
            'PNLM',
            'WRB8',
            'BDT3',
            'CB04',
            'TLP7',
            'PRG1',
            'PA15',
            'TWKD',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L833' } },
            { HL: { HL: 'L838' } },
            { HO: { HO: 'L838' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'SUT5',
            'VRE5',
            'SF28',
            'BBD0',
            'STG1',
            'TSP4'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        useDerivativeSpecificLogo: false,
        hasQOptions: false,
        showRenderApi: true,
        deploymentEnvironmentAvailability: CloudAccountEnv.DEV
    },
    AM509: {
        effectiveDate: '2000-01-01T00:00:00.000Z',
        sortOrder: 11,
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3019',
            'TRW2',
            'TRU2',
            'FG17',
            'PNL1',
            'WR25',
            'CB21',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HL: { HL: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'BBD0'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .definition
        },
        countryExcludes: ['CN'],
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AM572'
    },
    AM510: {
        sortOrder: 40,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3019',
            'TRW2',
            'TRU2',
            'FG17',
            'PNL1',
            'WR25',
            'CB21',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'BBD0',
            { BC: { BC: 'L802' } }
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .definition
        },
        countryExcludes: ['CN'],
        forceHidePricing: true,
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AM572'
    },
    AM539: {
        sortOrder: 30,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3037',
            'TRW1',
            'TRU5',
            'FG17',
            'HE27',
            'WL1K',
            'CB00',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'BBD0'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .definition
        },
        followUpModelYearCode: 'AM572',
        showRenderApi: false,
        hasQOptions: false
    },
    AM540: {
        sortOrder: 60,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3037',
            'TRW1',
            'TRU5',
            'FG17',
            'HE27',
            'WL1K',
            'CB00',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'BBD0',
            { BC: { BC: 'L802' } }
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        hasQOptions: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .definition
        },
        forceHidePricing: true,
        useDerivativeSpecificLogo: true,
        showRenderApi: false,
        followUpModelYearCode: 'AM572'
    },
    AM542: {
        sortOrder: 62,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3043',
            'TRW1',
            'TRU1',
            'FG17',
            'HE27',
            'WRB6',
            'BDT2',
            'TLP7',
            'PA15',
            'TWK5',
            'STG0',
            { TU: { TU: 'L845' } },
            'HLG1',
            'SB20',
            { CC: { CC: 'PE33' } },
            'SUTG',
            'VRC0',
            'SBE0',
            'SF28',
            'BBD0',
            'FMTL',
            'TE84',
            { BC: { BC: 'L828' } },
            'CB04'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        hidePerformanceStats: false,
        useDerivativeSpecificLogo: '2L',
        hasQOptions: false,
        showRenderApi: true,
        followUpModelYearCode: 'AM544'
    },
    AM544: {
        sortOrder: 62,
        effectiveDate: '2020-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3043',
            'TRW1',
            'TRU1',
            'FG17',
            'HE27',
            'WRB6',
            'BDT2',
            'TLP7',
            'PA15',
            'TWK5',
            'STG0',
            { TU: { TU: 'L845' } },
            'HLG1',
            'SB20',
            { CC: { CC: 'PE33' } },
            'SUTG',
            'VRC0',
            'SBE0',
            'SF28',
            'BBD0',
            'FMTL',
            'TE84',
            { BC: { BC: 'L828' } },
            'CB04'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        hidePerformanceStats: false,
        useDerivativeSpecificLogo: '2L',
        hasQOptions: false,
        showRenderApi: true,
        followUpModelYearCode: 'AM545'
    },
    AM545: {
        sortOrder: 62,
        effectiveDate: '2020-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3043',
            'TRW1',
            'TRU1',
            'FG17',
            'HE27',
            'WRB6',
            'BDT3',
            'TLP7',
            'PA15',
            'TWK5',
            'STG0',
            { TU: { TU: 'L845' } },
            'HLG1',
            'SB20',
            { CC: { CC: 'PE33' } },
            'SUTG',
            'VRC0',
            'SBE0',
            'SF28',
            'BBD0',
            'FMTL',
            'TE84',
            { BC: { BC: 'L828' } },
            'CB04',
            'TSP4'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        hidePerformanceStats: false,
        useDerivativeSpecificLogo: '2L',
        showRenderApi: true,
        hasQOptions: false
    },
    AM547: {
        sortOrder: 62,
        effectiveDate: '2030-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3043',
            'TRW1',
            'TRU1',
            'FG17',
            'HE27',
            'WRB6',
            'BDT3',
            'TLP7',
            'PA15',
            'TWK5',
            'STG0',
            { TU: { TU: 'L845' } },
            'HLG1',
            'SB20',
            { CC: { CC: 'PE33' } },
            'SUTG',
            'VRC0',
            'SBE0',
            'SF28',
            'BBD0',
            'FMTL',
            'TE84',
            { BC: { BC: 'L828' } },
            'CB04',
            'TSP4'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5x2')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        hidePerformanceStats: false,
        useDerivativeSpecificLogo: '2L',
        showRenderApi: true,
        hasQOptions: false,
        deploymentEnvironmentAvailability: CloudAccountEnv.DEV
    },
    AM569: {
        sortOrder: 20,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3078',
            'TRW2',
            'TRU2',
            'FG18',
            'PNL4',
            'WL1K',
            'CB04',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HL: { HL: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'BBD0'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .definition
        },
        followUpModelYearCode: 'AM572',
        showRenderApi: false,
        hasQOptions: false
    },
    AM570: {
        sortOrder: 50,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3078',
            'TRW2',
            'TRU2',
            'FG18',
            'PNL4',
            'WL1K',
            'CB04',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'BBD0',
            { BC: { BC: 'L802' } }
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM5')
                    .definition
        },
        forceHidePricing: true,
        showRenderApi: false,
        hasQOptions: false
    },
    AM606: {
        sortOrder: 10,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '3029',
            'FRE1',
            'TRW1',
            'TRU1',
            'FG54',
            'PNL0',
            'WR97',
            'BDT3',
            'CB02',
            'TLP7',
            'PRG0',
            'PAF3',
            'TWK7',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HL: { HL: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRC0',
            'SF28',
            'BBDK'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .definition
        },
        followUpModelYearCode: 'AM608',
        showRenderApi: false,
        hasQOptions: false
    },
    AM608: {
        sortOrder: 50,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '3029',
            'FRE1',
            'TRW1',
            'TRU1',
            'FG54',
            'PNL0',
            'WR97',
            'BDT3',
            'CB02',
            'TLP7',
            'PRG0',
            'PA36',
            'TWK7',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'RL09' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRC0',
            'SF28',
            'BBDK',
            'RDS3'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AM614'
    },
    AM636: {
        sortOrder: 20,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '3081',
            'FRE1',
            'TRW1',
            'TRU1',
            'FG33',
            'HE02',
            'WL0E',
            'BDT3',
            'CB04',
            'TLP7',
            'PRG0',
            'PAF3',
            'TWK7',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRC0',
            'SF28',
            'BBDK'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .definition
        },
        countryExcludes: ['RU'],
        followUpModelYearCode: 'AM638',
        showRenderApi: false,
        hasQOptions: false
    },
    AM638: {
        sortOrder: 60,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            'BOX7',
            '3081',
            'FRE1',
            'TRW1',
            'TRU1',
            'FG53',
            'HE02',
            'WL0E',
            'BDT3',
            'CB04',
            'TLP7',
            'PRG0',
            'PA36',
            'TWK7',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'RL09' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRC0',
            'SF28',
            'BBDK',
            'RDS3'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6')
                    .definition
        },
        countryExcludes: ['RU'],
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: false,
        hasQOptions: false
    },
    AM606F: {
        sortOrder: 30,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '2059',
            'GPBU',
            'FG54',
            'WRA8',
            'CB04',
            'BDT3',
            { ST: { ST: 'L700' } },
            { HL: { HL: 'RL76' } },
            'BBDK',
            'TRU1',
            'TRW1',
            'PNL1',
            'PA24',
            'STG0',
            'SB01',
            'SUT7',
            'VRC0',
            'SF30',
            'TLP7',
            'BPK0',
            'MSC',
            'HUD7',
            'SBP5'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        followUpModelYearCode: 'AM608F',
        showRenderApi: false,
        hasQOptions: false
    },
    AM608F: {
        sortOrder: 70,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '2059',
            'GPBU',
            'FG54',
            'WRA8',
            'CB04',
            'BDT3',
            { ST: { ST: 'L700' } },
            { HO: { HO: 'RL76' } },
            'BBDK',
            'TRU1',
            'TRW1',
            'PNL1',
            'PA24',
            'STG0',
            'SB01',
            'SUT7',
            'VRC0',
            'SF30',
            'TLP7',
            'BPK0',
            'MSC',
            'HUD7',
            'SBP5',
            { SU: { SU: 'TH07' } }
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: false,
        hasQOptions: false
    },
    AM614: {
        sortOrder: 40,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            '3082',
            'FRE1',
            'TRW1',
            'TRU1',
            'FG54',
            'PNL0',
            'WRBY',
            'BDT3',
            'CB04',
            'TLP7',
            'PRG0',
            'PA24',
            'TWK3',
            { TU: { TU: 'L700' } },
            { TL: { TL: 'L700' } },
            'STG2',
            { HL: { HL: 'RL51' } },
            { HO: { HO: 'RL51' } },
            'SUTG',
            'UTI1',
            'LTI1',
            'CSI3',
            'SF30',
            'AU16',
            'BBD0',
            'SB01',
            'PRE0',
            'GPC4',
            'FMTK',
            { CC: { CC: 'PE31' } },
            { SU: { SU: 'TH99' } }
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6x4')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6x4')
                    .definition
        },
        countryExcludes: [],
        hasQOptions: false,
        showRenderApi: true,
        forceHidePricing: MENAT_COUNTRIES
    },
    AM616: {
        sortOrder: 40,
        effectiveDate: '2030-01-01T00:00:00.000Z',
        haloSpec: [
            '3196',
            'TRW1',
            'TRU1',
            'STR4',
            'GPC5',
            'GC30',
            'GT08',
            'FG54',
            'HUD1',
            'PRG0',
            'HT00',
            'PNL4',
            'WRBJ',
            'BDT3',
            'CB69',
            'MSC3',
            'OF07',
            'TLP7',
            'PRE1',
            'TWK3',
            'PA24',
            { TU: { TU: 'L700' } },
            { TL: { TL: 'L700' } },
            { HO: { HO: 'M001' } },
            { HL: { HL: 'M001' } },
            'AMB0',
            'STG2',
            { SU: { SU: 'TJ30' } },
            { SL: { SL: 'TJ30' } },
            'SEP0',
            'ANT3',
            'EF02',
            'TE84',
            'SB01',
            { CC: { CC: 'PE31' } },
            'NW01',
            'BOT3',
            'SUT6',
            { SC: { SC: 'L7S2' } },
            'ANT1',
            'UTI2',
            'LTI2',
            'CSI3',
            'PSCP',
            'SF30',
            'BBDK',
            'RDS3',
            'PIPU',
            'XBR1',
            'CFS2',
            'PL08',
            'AU16',
            '7PCC',
            'APS1',
            'BSA1',
            'KLG1'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6x4')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6x4')
                    .definition
        },
        countryExcludes: [],
        hasQOptions: false,
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: true,
        deploymentEnvironmentAvailability: CloudAccountEnv.UAT
    },
    AM636F: {
        sortOrder: 40,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '2059',
            'GPBU',
            'FG54',
            'WRA8',
            'CB04',
            'BDT3',
            { ST: { ST: 'L700' } },
            { HO: { HO: 'M001' } },
            'BBDK',
            'TRU1',
            'TRW1',
            'HE03',
            'PA24',
            'STG0',
            'SB01',
            'SUT7',
            'VRC0',
            'SF30',
            'TLP7',
            'BPK0',
            'MSC',
            'HUD7',
            'SBP5'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        followUpModelYearCode: 'AM638F',
        showRenderApi: false,
        hasQOptions: false
    },
    AM638F: {
        sortOrder: 80,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            'BOX7',
            '2059',
            'GPBU',
            'FG54',
            'WRA8',
            'CB04',
            'BDT3',
            { ST: { ST: 'L700' } },
            { SU: { SU: 'TH07' } },
            { HO: { HO: 'M001' } },
            'BBDK',
            'TRU1',
            'TRW1',
            'HE03',
            'PA24',
            'STG0',
            'SB01',
            'SUT7',
            'VRC0',
            'SF30',
            'TLP7',
            'BPK0',
            'MSC',
            'HUD7',
            'SBP5'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: false,
        hasQOptions: false
    },
    AM646: {
        sortOrder: 90,
        effectiveDate: '2030-01-01T00:00:00.000Z',
        haloSpec: [
            '3239',
            'TRW1',
            'TRU1',
            'STR4',
            'GPC4',
            'GC12',
            'GT08',
            'FG54',
            'HUD1',
            'HT00',
            'HE03',
            'WRBY',
            'BDT3',
            'CB04',
            'MSC3',
            'OF07',
            'TLP7',
            'PRE1',
            'TWK5',
            'PA24',
            { TU: { TU: 'L830' } },
            { TL: { TL: 'L830' } },
            { HO: { HO: 'RL66' } },
            { HL: { HL: 'RC09' } },
            'AMB0',
            'STG0',
            { SU: { SU: 'TH90' } },
            { SL: { SL: 'TH90' } },
            'SEPL',
            'TE46',
            'EF02',
            'TE46',
            { WT: { WT: 'L830' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'NW01',
            'BOT4',
            'SUTG',
            { SC: { SC: 'L7S4' } },
            'ANT1',
            'UTI1',
            'LTI1',
            'CSI6',
            'PSC2',
            'SF30',
            'BBD0',
            'RDS3',
            'PIPU',
            'XBR1',
            'CFS3',
            'PL44',
            'AU18',
            'FMTL',
            'BSA1'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6x4')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM6x4')
                    .definition
        },
        countryExcludes: [],
        hasQOptions: false,
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: true,
        deploymentEnvironmentAvailability: CloudAccountEnv.UAT
    },
    AM668: {
        sortOrder: 100,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            'BOX7',
            '1959',
            'GPBW',
            'GLA2',
            'GLF0',
            'GLS0',
            'TRW2',
            'BNT2',
            'FG53',
            'HE27',
            'WR6J',
            'BDT2',
            'AER7',
            'CB04',
            'TLP7',
            'PRG0',
            'PA13',
            'TWK9',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L800' } },
            'STG2',
            { SU: { SU: 'TH07' } },
            { HL: { HL: 'RL09' } },
            'SB10',
            { CC: { CC: 'PE31' } },
            'SUT8',
            'VRC4',
            'SF36',
            'TSP4',
            'BBD0',
            'PIPN',
            'TCN0',
            'LWP1',
            { HO: { HO: 'RL02' } }
        ],
        isAuthorizedOnly: false,
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: true,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs: {
                TWK8: [{ TU: { TU: 'L838' } }],
                TWK9: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L800' } }],
                TWKA: [{ TU: { TU: 'L838' } }],
                TWKB: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L800' } }]
            },
            definition: [
                {
                    name: 'Full Semi Aniline',
                    defaultRelatedOption: 'TWK9',
                    relatedOptions: ['TWK8', 'TWK9']
                },
                {
                    name: 'Alcantara and Semi Aniline',
                    defaultRelatedOption: 'TWKB',
                    relatedOptions: ['TWKA', 'TWKB'],
                    default: true
                }
            ]
        },
        countryExcludes: ['CN', 'RU', 'UA'],
        hasQOptions: true,
        hidePerformanceStats: false,
        showRenderApi: false,
        forceHidePricing: true
    },
    AM688: {
        sortOrder: 90,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'BOX7',
            '3080',
            'GPBX',
            'GLA5',
            'GLF1',
            'GLS0',
            'TRW5',
            'TRU7',
            'BNT4',
            'FG53',
            'WR5U',
            'BDT2',
            'CB02',
            'TLP7',
            'PRG0',
            'PA13',
            'TWKB',
            { TU: { TU: 'L830' } },
            { TL: { TL: 'L800' } },
            'STG2',
            { SU: { SU: 'TH07' } },
            { HL: { HL: 'RL46' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'VRC4',
            'BBD0',
            'PIPS',
            'SF28',
            'SUT6',
            'TE63',
            { HO: { HO: 'RL02' } },
            'AER8',
            'TCN0',
            'LWP1'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: true,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs: {
                TWK8: [{ TU: { TU: 'L830' } }],
                TWK9: [{ TU: { TU: 'L830' } }, { TL: { TL: 'L800' } }],
                TWKA: [{ TU: { TU: 'L830' } }],
                TWKB: [{ TU: { TU: 'L830' } }, { TL: { TL: 'L800' } }]
            },
            definition: [
                {
                    name: 'Full Semi Aniline',
                    defaultRelatedOption: 'TWK9',
                    relatedOptions: ['TWK8', 'TWK9']
                },
                {
                    name: 'Alcantara and Semi Aniline',
                    defaultRelatedOption: 'TWKB',
                    relatedOptions: ['TWKA', 'TWKB'],
                    default: true
                }
            ]
        },
        countryExcludes: ['CN', 'RU', 'UA'],
        hasQOptions: true,
        showRenderApi: false,
        forceHidePricing: true
    },
    AM690: {
        sortOrder: 100,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            'EXB0',
            '3173',
            'GRS0',
            'TRW2',
            'TRU5',
            'BNT2',
            'SBPA',
            'CHR1',
            'WL1U',
            'BDT2',
            'CB04',
            'PA13',
            'TWKF',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L827' } },
            { HL: { HL: 'L827' } },
            { HO: { HO: 'L838' } },
            { CC: { CC: 'PE32' } },
            'STG0',
            'BAP1',
            'SB01',
            'SCRP',
            'SUT8',
            'TXC2',
            'GSS6',
            'GRKC',
            'RS30',
            'IAQM',
            'DSP7',
            'BBD0',
            'LAE3',
            'PIPW',
            'EF48',
            '1GR0',
            '2GR0',
            '3GR0',
            '4GR0'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: true,
        useDerivativeSpecificLogo: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        countryExcludes: [],
        hasQOptions: false,
        forceHidePricing: MENAT_COUNTRIES,
        isAuthorizedOnly: false,
        hidePerformanceStats: true,
        disableInteriorEnvironmentSelection: true,
        disableBrochureAccessForPublic: true,
        showRenderApi: true,
        disableOrderForPublic: true
    },
    AM705: {
        sortOrder: 10,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3027',
            'TRW5',
            'TRU6',
            'PNL6',
            'WR9Q',
            'BDT2',
            'CB07',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG3',
            { HL: { HL: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'EBP8',
            'PIPM'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .definition
        },
        followUpModelYearCode: 'AM709',
        showRenderApi: false,
        hasQOptions: false
    },
    AM706: {
        sortOrder: 30,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3027',
            'TRW5',
            'TRU6',
            'PNL6',
            'WR9Q',
            'BDT2',
            'CB07',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG3',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'EBP8',
            'PIPM',
            { WT: { WT: 'L802' } }
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .definition
        },
        forceHidePricing: true,
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AM709'
    },
    AM709: {
        sortOrder: 50,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3011',
            'GPC2',
            'GLE2',
            'GLG2',
            'GLH0',
            'TRW5',
            'TRU6',
            'PNL6',
            'WRBW',
            'BDT2',
            'CB02',
            'TLP7',
            'PRG1',
            'TWKD',
            'PA13',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L832' } },
            'STG4',
            { WT: { WT: 'L818' } },
            { HL: { HL: 'L838' } },
            'SB01',
            { CC: { CC: 'PE36' } },
            'SUT6',
            'VRB0',
            'SBE1',
            'SF30',
            'EBP8',
            'PIPD',
            { HO: { HO: 'L832' } },
            'EF02'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        hasQOptions: true,
        useDerivativeSpecificLogo: '2L',
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7x9')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7x9')
                    .definition
        },
        showRenderApi: false,
        disableOrderForDealersExcept: [''],
        forceHidePricing: MENAT_COUNTRIES
    },
    AM735: {
        sortOrder: 20,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofClosed',
            '3082',
            'TRW5',
            'TRU6',
            'HE03',
            'WRA0',
            'BDT2',
            'CB04',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG3',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'EBP8',
            'PIPM'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .definition
        },
        followUpModelYearCode: 'AM739',
        showRenderApi: false,
        hasQOptions: false
    },
    AM736: {
        sortOrder: 40,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3082',
            'TRW5',
            'TRU6',
            'HE03',
            'WRA0',
            'BDT2',
            'CB04',
            'TLP7',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG3',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUT6',
            'VRB1',
            'SBE1',
            'SF28',
            'EBP8',
            'PIPM',
            { WT: { WT: 'L802' } }
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7')
                    .definition
        },
        forceHidePricing: true,
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AM739'
    },
    AM739: {
        sortOrder: 60,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODERoofOpen',
            '3011',
            'GPC2',
            'GLE9',
            'GLG2',
            'GLH0',
            'TRW5',
            'TRU6',
            'HE27',
            'WRBW',
            'BDT2',
            'CB02',
            'TLP7',
            'PRG0',
            'TWKD',
            'PA13',
            { TU: { TU: 'L838' } },
            { TL: { TL: 'L832' } },
            'STG4',
            { WT: { WT: 'L818' } },
            { HL: { HL: 'L838' } },
            'SB01',
            { CC: { CC: 'PE36' } },
            'SUT6',
            'VRB0',
            'SBE1',
            'SF30',
            'EBP8',
            'PIPD',
            { HO: { HO: 'RL09' } },
            'EF02'
        ],
        hasRoofBlind: false,
        isConvertible: true,
        isLimitedEdition: false,
        hasQOptions: true,
        useDerivativeSpecificLogo: '2L',
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7x9')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM7x9')
                    .definition
        },
        showRenderApi: false,
        disableOrderForDealersExcept: [''],
        forceHidePricing: MENAT_COUNTRIES
    },
    AM802: {
        sortOrder: 10,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3018',
            'TRW1',
            'TRU1',
            'FG17',
            'WR4U',
            'CB04',
            'TLP5',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUTB',
            'VR0G',
            'SF28',
            'EBP3',
            'TY03'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8')
                    .definition
        },
        followUpModelYearCode: 'AM804',
        showRenderApi: false,
        hasQOptions: false
    },
    AM804: {
        sortOrder: 40,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3018',
            'TRW1',
            'TRU1',
            'FG17',
            'WR4U',
            'CB04',
            'TLP5',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUTB',
            'VR0G',
            'SF28',
            'EBP3',
            'TY03'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        followUpModelYearCode: 'AM806',
        showRenderApi: false,
        hasQOptions: false
    },
    AM806: {
        sortOrder: 41,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3018',
            'TRW1',
            'TRU1',
            'FG17',
            'WR4U',
            'CB04',
            'TLP5',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUTB',
            'VR0G',
            'SF28',
            'EBP3',
            'TY03',
            'PRG1',
            { BC: { BC: 'L802' } }
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: false,
        hasQOptions: false
    },
    AM862: {
        sortOrder: 20,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3018',
            'TRW1',
            'TRU1',
            'FG17',
            'WR0S',
            'CB02',
            'TLP5',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUTB',
            'VR0G',
            'SF28',
            'EBP3',
            'TY03',
            'PTB0'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8')
                    .definition
        },
        forceHidePricing: MENAT_COUNTRIES,
        countryIncludes: ['CN'],
        followUpModelYearCode: 'AM864',
        showRenderApi: false,
        hasQOptions: false
    },
    AM864: {
        sortOrder: 50,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3013',
            'TRW1',
            'TRU1',
            'FG17',
            'WR0S',
            'CB02',
            'TLP5',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUTB',
            'VR0G',
            'SF28',
            'EBP3',
            'TY03'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .definition
        },
        countryIncludes: ['CN'],
        forceHidePricing: MENAT_COUNTRIES,
        followUpModelYearCode: 'AM866',
        showRenderApi: false,
        hasQOptions: false
    },
    AM866: {
        sortOrder: 51,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3013',
            'TRW1',
            'TRU1',
            'FG17',
            'WR0S',
            'CB02',
            'TLP5',
            'PRG0',
            'TWK6',
            { TU: { TU: 'L802' } },
            { TL: { TL: 'L833' } },
            'STG0',
            { HO: { HO: 'L802' } },
            'SB01',
            { CC: { CC: 'PE31' } },
            'SUTB',
            'VR0G',
            'SF28',
            'EBP3',
            'TY03',
            'PTB0',
            { BC: { BC: 'L828' } }
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        interiorEnvironment: {
            haloSpecs:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .haloSpecs,
            definition:
                getDerivativeInteriorEnvironmentDefaultSelections('AM8_MY23')
                    .definition
        },
        countryIncludes: ['CN'],
        hasQOptions: false,
        showRenderApi: false,
        forceHidePricing: MENAT_COUNTRIES
    },
    AM884: {
        sortOrder: 30,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3022',
            'TRW9',
            'TRU2',
            'FG18',
            'WR4U',
            'BDT0',
            'CB07',
            'TLP7',
            'PRG1',
            'TWKD',
            { TU: { TU: 'L814' } },
            { TL: { TL: 'L809' } },
            'STG1',
            { HO: { HO: 'L838' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'SUTF',
            'VR0F',
            'SF43',
            'EBP6',
            'TY03'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: true,
        interiorEnvironment: {
            haloSpecs: {
                TWK3: [{ TU: { TU: 'L700' } }, 'STG0'],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L706' } }, 'STG1'],
                TWKC: [{ TU: { TU: 'L838' } }, 'STG0'],
                TWKD: [{ TU: { TU: 'L814' } }, { TL: { TL: 'L809' } }, 'STG1'],
                TWK5: [{ TU: { TU: 'L838' } }, 'STG0'],
                TWK6: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L801' } }, 'STG1'],
                TWK7: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L801' } }, 'STG1']
            },
            definition: [
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Comfort',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6', 'TWK7']
                },
                {
                    name: 'Inspire Sport',
                    defaultRelatedOption: 'TWKD',
                    relatedOptions: ['TWKC', 'TWKD'],
                    default: true
                }
            ]
        },
        forceHidePricing: MENAT_COUNTRIES,
        followUpModelYearCode: 'AM886',
        showRenderApi: false,
        hasQOptions: false
    },
    AM886: {
        sortOrder: 31,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3022',
            'TRW9',
            'TRU2',
            'FG18',
            'WR4U',
            'BDT0',
            'CB07',
            'TLP7',
            'PRG1',
            'TWKD',
            { TU: { TU: 'L814' } },
            { TL: { TL: 'L809' } },
            'STG1',
            { HO: { HO: 'L814' } },
            'SB01',
            { CC: { CC: 'PE32' } },
            'SUTF',
            'VR0F',
            'SF43',
            'EBP6',
            'TY03',
            { BC: { BC: 'L845' } },
            'AMRF'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: true,
        interiorEnvironment: {
            haloSpecs: {
                TWK3: [{ TU: { TU: 'L700' } }, 'STG0'],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L706' } }, 'STG1'],
                TWKC: [{ TU: { TU: 'L838' } }, 'STG0'],
                TWKD: [{ TU: { TU: 'L814' } }, { TL: { TL: 'L809' } }, 'STG1'],
                TWK5: [{ TU: { TU: 'L838' } }, 'STG0'],
                TWK6: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L801' } }, 'STG1'],
                TWK7: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L801' } }, 'STG1']
            },
            definition: [
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Comfort',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6', 'TWK7']
                },
                {
                    name: 'Inspire Sport',
                    defaultRelatedOption: 'TWKD',
                    relatedOptions: ['TWKC', 'TWKD'],
                    default: true
                }
            ]
        },
        forceHidePricing: MENAT_COUNTRIES,
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AM888'
    },
    AM888: {
        sortOrder: 32,
        effectiveDate: '2020-01-01T00:00:00.000Z',
        haloSpec: [
            'MHPCODELGTDRL',
            'MHPCODESunroofOpen',
            '3039',
            'TRW9',
            'TRU1',
            'FG18',
            'PRG1',
            'WR6A',
            'CB04',
            'TLP7',
            'TWKC',
            'PA24',
            { TU: { TU: 'L814' } },
            { TL: { TL: 'L814' } },
            { ST: { ST: 'L814' } },
            { HO: { HO: 'L814' } },
            { HL: { HL: 'RL76' } },
            'STG0',
            { SU: { SU: 'TH72' } },
            { SL: { SL: 'TH72' } },
            { WT: { WT: 'L814' } },
            { WS: { WS: 'L814' } },
            'SEPR',
            'TE78',
            'EF02',
            'TE78',
            'SB01',
            { CC: { CC: 'PE32' } },
            'NW01',
            'BOT4',
            'SUTG',
            { SC: { SC: 'L814' } },
            'ANT1',
            'VR0E',
            'SF43',
            'EBP3',
            'TWB0',
            'PBL1',
            'RK05',
            'TY03',
            'CFS1',
            'PL46',
            'SSW3',
            'AU17',
            'FMTL',
            'KEY6',
            'PHC1',
            'UM01',
            '7PCC',
            'APS1',
            'BSA1'
        ],
        hasRoofBlind: true,
        isConvertible: false,
        isLimitedEdition: false,
        useDerivativeSpecificLogo: true,
        interiorEnvironment: {
            haloSpecs: {
                TWK3: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L700' } }, 'STG0'],
                TWK4: [{ TU: { TU: 'L700' } }, { TL: { TL: 'L706' } }, 'STG1'],
                TWKC: [{ TU: { TU: 'L819' } }, { TL: { TL: 'L819' } }, 'STG0'],
                TWKD: [{ TU: { TU: 'L814' } }, { TL: { TL: 'L809' } }, 'STG1'],
                TWK5: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L838' } }, 'STG0'],
                TWK6: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L801' } }, 'STG1'],
                TWK7: [{ TU: { TU: 'L838' } }, { TL: { TL: 'L801' } }, 'STG1']
            },
            definition: [
                {
                    name: 'Accelerate',
                    defaultRelatedOption: 'TWK4',
                    relatedOptions: ['TWK3', 'TWK4']
                },
                {
                    name: 'Inspire Comfort',
                    defaultRelatedOption: 'TWK6',
                    relatedOptions: ['TWK5', 'TWK6', 'TWK7']
                },
                {
                    name: 'Inspire Sport',
                    defaultRelatedOption: 'TWKD',
                    relatedOptions: ['TWKC', 'TWKD'],
                    default: true
                }
            ]
        },
        forceHidePricing: MENAT_COUNTRIES,
        showRenderApi: true,
        hasQOptions: false
    },
    AMR003: {
        sortOrder: 10,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: ['SPEC_SPECTRAL_SILVER'],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        showRenderApi: false,
        hasQOptions: false
    },
    AMR23: {
        sortOrder: 10,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: ['SPEC_SPECTRAL_SILVER'],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        hasQOptions: false,
        showRenderApi: false,
        followUpModelYearCode: 'AMR24'
    },
    AMR24: {
        sortOrder: 10,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: ['SPEC_SPECTRAL_SILVER'],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        showRenderApi: false,
        hasQOptions: false
    },
    LVR: {
        sortOrder: 11,
        effectiveDate: '2000-01-01T00:00:00.000Z',
        haloSpec: [
            'FPCFS',
            'FUPCFS',
            'FBARB',
            'RBARB',
            'RBPAV',
            'PPPAV',
            'BBB',
            'SPCFS',
            'SRC',
            'HCS',
            'L802',
            'LHS',
            'CSP',
            'ACC'
        ],
        hasRoofBlind: false,
        isConvertible: false,
        isLimitedEdition: false,
        isAuthorizedOnly: false,
        interiorEnvironment: {
            haloSpecs: {},
            definition: []
        },
        showRenderApi: false,
        hasQOptions: false
    }
});

/**
 * Add model-information here.
 */
const MODEL_STATIC_INFO = <{ [modelId: string]: ModelStaticInfo }>deepLock(<
    { [modelId: string]: ModelStaticInfo }
>{
    M255: {
        sortOrder: 999
    },
    M222: {
        sortOrder: 60
    },
    M200: {
        sortOrder: 0
    },
    M210: {
        sortOrder: 20
    },
    M220: {
        sortOrder: 30
    },
    M230: {
        sortOrder: 10
    },
    M212: {
        sortOrder: 50
    }
});

export function getDerivativeStaticInfo(
    derivativeId: string
): DerivativeStaticInfo {
    const foundInfo = DERIVATIVE_STATIC_INFO[derivativeId.toUpperCase()];
    if (!foundInfo) {
        throw new Error(
            `MISSING STATIC DERIVATIVE INFO FOR DERIVATIVE ${derivativeId}`
        );
    }
    return foundInfo;
}

/**
 * Get all DerivativeStaticInfo items.
 * Note that the returned array is calculated on each call so you might want
 * to consider caching it when calling it often.
 */
export function getAllDerivativeStaticInfo(): {
    id: string;
    derivativeInfo: DerivativeStaticInfo;
}[] {
    return Object.entries(DERIVATIVE_STATIC_INFO).map((entry) => ({
        id: entry[0],
        derivativeInfo: entry[1]
    }));
}

export function getAllRenderApiDerivates(): string[] {
    return getAllDerivativeStaticInfo()
        .filter(
            (staticDerivateInfo) =>
                staticDerivateInfo.derivativeInfo.showRenderApi
        )
        .map((derivates) => derivates.id);
}

export function getModelStaticInfo(modelId: string): ModelStaticInfo {
    const foundInfo = MODEL_STATIC_INFO[modelId.toUpperCase()];
    if (!foundInfo) {
        throw new Error(`MISSING STATIC MODEL INFO FOR MODEL ${modelId}`);
    }
    return foundInfo;
}

export function getModelCarTypeForRendering(productId: string): string {
    if (getDerivativeStaticInfo(productId).hasRoofBlind) {
        return 'R';
    }
    return getDerivativeStaticInfo(productId).isConvertible ? 'V' : 'C';
}

export function getAllDerivateCodes(): string[] {
    return CONFIGURATOR_DERIVATES;
}

export function getAllVisualizerDerivateCodes(): string[] {
    return VISUALIZER_DERIVATES;
}

export function getDerivateCodesForModelCode(modelCode: string): string[] {
    return Object.values(MODELCODES[modelCode] || []) || [];
}

export function getAllCPQModelCodes(): string[] {
    return Object.keys(MODELCODES);
}

export function getModelCodeForDerivateCode(
    derivateCode: string
): string | undefined {
    for (const [modelCode, derivateCodes] of Object.entries(MODELCODES)) {
        if (derivateCodes.includes(derivateCode)) {
            return modelCode;
        }
    }
    return undefined;
}

/*
 * Checks to see if the derivative has a followUpModelYearCode
 * Returns the new code or the old code
 */
export function resolveCurrentModelYearProductId(derivativeId: string): string {
    const info = getDerivativeStaticInfo(derivativeId);

    if (info.followUpModelYearCode) {
        return resolveCurrentModelYearProductId(info.followUpModelYearCode);
    }

    return derivativeId;
}

export function getCapCodeForDerivateCode(derivateCode: string): string {
    const capCode = CAP_CODES[derivateCode];
    if (!capCode) {
        throw new Error(`Cap Code missing for derivate-code: ${derivateCode}`);
    }
    return capCode;
}

/**
 * Get the M-based model-family code for a legacy model code (e.g. AM8 -> M230).
 * @param legacyModelCode The legacy model code.
 * @returns The M-based model-family code or undefined if the legacy model code is not mapped.
 */
export function getMappedLegacyModelCode(
    legacyModelCode: string
): string | undefined {
    return LEGACY_MODEL_FAMILY_MAPPING[legacyModelCode];
}
